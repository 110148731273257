import { Close } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { alpha, Box, Button, Card, CardContent, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Stack, Table, Tooltip } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { DayCalendarSkeleton, PickersDay, StaticDatePicker } from '@mui/x-date-pickers-pro'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { DateTime } from 'luxon'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { toHumanReadable } from '~/helpers/stringHelpers'
import { useJobBookingDialog } from '~/hooks/dialogs/useJobBookingDialog'
import { useCrud } from '~/hooks/useCrud'
import { useGate } from '~/hooks/useGate'
import { bookingCalendarAvailabilityQuery } from '~/loaders/bookingsLoader'
import { jobCategoriesQuery } from '~/loaders/jobCategoriesLoader'
import { createBooking, updateBooking } from '~/requests/bookings'
import { searchSubmissions } from '~/requests/submissions'
import FacilityDropdown from '../Dropdowns/FacilityDropdown'
import GlobalSearch from '../GlobalSearch'
import OptionalComponent from '../OptionalComponent'
import SubmissionSummaryTile from '../SubmissionSummaryTile'

const JobBookingDialog = ({ isOpen, onClose }) => {
	const { estimate, booking, facilityId, setFacilityId, targetDate } = useJobBookingDialog();
	const [selectedEstimate, setSelectedEstimate] = useState(estimate);
	const [isSaving, setIsSaving] = useState(false);
	const { gate } = useGate();

	const theme = useTheme();
	const crud = useCrud({ createRequest: createBooking, updateRequest: updateBooking, queryKey: 'bookings' });

	const [selectedDate, setSelectedDate] = useState(targetDate);
	const [calendarViewDates, setCalendarViewDates] = useState([DateTime.now().startOf('month'), DateTime.now().endOf('month')]);

	const { data: jobCategories } = useQuery(jobCategoriesQuery());

	const jobCategory = useMemo(() => jobCategories?.find((category) => category.key === selectedEstimate?.meta?.job_category), [jobCategories, selectedEstimate]);

	const { data: availabilityData = {}, isLoading: availabilityLoading } = useQuery(bookingCalendarAvailabilityQuery(facilityId, jobCategory?.id, calendarViewDates[0].toISO(), calendarViewDates[1].toISO()));

	useEffect(() => {
		setSelectedEstimate(estimate);
	}, [estimate]);

	useEffect(() => {
		setSelectedDate(targetDate);
	}, [targetDate]);

	const handleOnClose = useCallback(() => {
		setSelectedEstimate(null);
		onClose();
	}, [setSelectedEstimate]);

	const searchModels = useMemo(() => [
		{
			key: 'submissions',
			label: 'Estimates',
			endpoint: searchSubmissions,
			onClick: (item) => {
				setSelectedEstimate(item);
			},
			render: (item, key, onClick) => <SubmissionSummaryTile key={key} submission={item} onClick={onClick} showAuthor />,
			params: {
				form: 'estimate',
				status: 'completed'
			}
		}

	], [setSelectedEstimate]);


	const shouldDisableDate = useCallback((data, date) => {
		if (Object.keys(data).length === 0) return false;
		return (
			data[date.toIso8601()]?.status === 'no_slots'
			|| data[date.toIso8601()]?.status === 'blocked'
			|| (data[date.toIso8601()]?.status === 'at_capacity' && !gate('tenant.apps.bookings.ignore_capacity'))
		);
	}, []);

	const queryClient = useQueryClient();

	const saveBooking = useCallback(() => {
		const data = booking ? {
			facility_uuid: facilityId,
			booking_datetime: selectedDate.toISO(),
		} : {
			submission_id: selectedEstimate.id,
			facility_uuid: facilityId,
			job_category_id: jobCategory.id,
			booking_datetime: selectedDate.toISO(),
		};

		setIsSaving(true);

		crud.doSave({
			id: booking?.id || null,
			values: data,
			toastLabel: 'Booking',
			onSettled: () => {
				setIsSaving(false);
			},
			onSuccess: () => {
				handleOnClose();
				queryClient.invalidateQueries({
					queryKey: ['form', 'estimate', 'submission', selectedEstimate.uuid],
				});

				queryClient.invalidateQueries({
					queryKey: ['bookings'],
				});
			},
			onError: (error, errors) => console.error(error)
		});

	}, [selectedEstimate, facilityId, jobCategory, selectedDate]);

	const CustomDayPicker = useCallback((props) => {
		const { availabilityData = {}, day, outsideCurrentMonth, today, disabled, ...other } = props;

		const status = availabilityData[day.toIso8601()]?.status;

		const isDisabled = shouldDisableDate(availabilityData, day);

		const map = {
			available: {
				borderStyle: 'solid',
				borderWidth: '1px',
				borderColor: 'success.main',
				color: 'success.main',
				fontWeight: 'bold',
				backgroundColor: alpha(theme.palette.success.light, 0.1),
			},
			at_capacity: {
				borderStyle: 'solid',
				borderWidth: '1px',
				borderColor: 'error.main',
				color: 'error.main',
			},
			no_slots: {},
			blocked: {
				borderStyle: 'solid',
				borderWidth: '1px',
				borderColor: 'grey.500',
				'&::after': {
					content: '""',
					position: 'absolute',
					top: 0,
					left: '50%',
					borderRadius: '50%',
					width: '1px',
					rotate: '-45deg',
					height: '100%',
					backgroundColor: 'grey.500',
				}
			},
		};

		return (
			<OptionalComponent wrapper={Tooltip} condition={status === 'blocked'} wrapperProps={{ title: `Blocked Reason: ${availabilityData[day.toIso8601()]?.blocked}` }}>
				<PickersDay {...other}
					disabled={disabled || isDisabled}
					outsideCurrentMonth={outsideCurrentMonth}
					day={day}
					sx={{
						...(map[status] || {}),
						position: 'relative',
						'&.Mui-selected': {
							backgroundColor: (status === 'available' ? 'success.main' : 'error.main'),
							'&:hover,:focus': {
								backgroundColor: (status === 'available' ? 'success.main' : 'error.main'),
							}
						},
						...(today ? { textDecoration: 'underline', fontWeight: 'bold' } : {})
					}}
					today={false}
				/>
			</OptionalComponent>
		);
	}, []);

	return (
		<Dialog open={isOpen} onClose={handleOnClose} fullWidth maxWidth="md">
			<DialogTitle display="flex" alignItems="center" justifyContent="space-between">
				<div>Job Booking</div>
				<IconButton aria-label="close" onClick={handleOnClose} edge="end">
					<Close />
				</IconButton>
			</DialogTitle>
			<DialogContent>
				{!selectedEstimate ? (
					<GlobalSearch models={searchModels} />
				) : (
					<Box display="flex" gap={3} mt={1}>
						<Box width="50%">
							<Stack spacing={2}>
								<Card variant="outlined">
									<CardContent>
										<Table>
											<tr>
												<td>Job Category</td>
												<td style={{ fontWeight: 'bold' }}>{toHumanReadable(selectedEstimate.meta?.job_category)}</td>
											</tr>
											<tr>
												<td>Claim No</td>
												<td style={{ fontWeight: 'bold' }}>{selectedEstimate.summary?.claim_number}</td>
											</tr>
											<tr>
												<td>Rego</td>
												<td style={{ fontWeight: 'bold' }}>{selectedEstimate.summary?.rego}</td>
											</tr>
											<tr>
												<td>Customer</td>
												<td style={{ fontWeight: 'bold' }}>{selectedEstimate.summary?.customer}</td>
											</tr>
											<tr>
												<td>Vehicle</td>
												<td style={{ fontWeight: 'bold' }}>{selectedEstimate.summary?.vehicle}</td>
											</tr>
											<tr>
												<td>Mobile</td>
												<td style={{ fontWeight: 'bold' }}>{selectedEstimate.summary?.mobile}</td>
											</tr>
											<tr>
												<td>Email</td>
												<td style={{ fontWeight: 'bold' }}>{selectedEstimate.summary?.email}</td>
											</tr>
										</Table>
									</CardContent>
								</Card>
								{/* <Card variant="outlined">
									<CardContent>
										<Table>
											<tr>
												<td>Booking</td>
												<td style={{ fontWeight: 'bold' }}>{toHumanReadable(selectedEstimate.meta?.job_category)}</td>
											</tr>
											<tr>
												<td>Claim No</td>
												<td style={{ fontWeight: 'bold' }}>{selectedEstimate.summary?.claim_number}</td>
											</tr>
										</Table>
									</CardContent>
								</Card> */}
							</Stack>
						</Box>
						<Box width="50%" display="flex" flexDirection="column" gap={2}>
							<FacilityDropdown
								value={facilityId}
								fullWidth
								onChange={(name, value) => setFacilityId(value)}
							/>

							<Box>
								<StaticDatePicker
									value={selectedDate}
									disabled={!facilityId}
									displayWeekNumber
									loading={availabilityLoading}
									renderLoading={() => <DayCalendarSkeleton />}
									slots={{
										day: CustomDayPicker,
									}}
									slotProps={{
										day: {
											availabilityData
										},
										actionBar: { actions: [] }
									}}
									onMonthChange={date => setCalendarViewDates([date.startOf('month'), date.endOf('month')])}
									sx={{

									}}
									onChange={setSelectedDate}
								/>
								{!availabilityData && (
									<Box textAlign="center">No available dates</Box>
								)}
							</Box>
						</Box>
					</Box>
				)}
			</DialogContent>
			<DialogActions>
				<Button color='secondary' onClick={handleOnClose} sx={{ mr: 'auto' }}>
					Cancel
				</Button>
				{selectedEstimate && (
					<>
						<Button variant='outlined' onClick={() => setSelectedEstimate(null)}>
							Back
						</Button>
						<LoadingButton loading={isSaving} variant='contained' disabled={!selectedDate} onClick={saveBooking}>
							{booking?.id ? 'Update Booking' : 'Create Booking'}
						</LoadingButton>
					</>
				)}
			</DialogActions>
		</Dialog>
	)
}

export default JobBookingDialog