import { Add, Close } from '@mui/icons-material';
import DirectionsCarFilledIcon from '@mui/icons-material/DirectionsCarFilled';
import EmailIcon from '@mui/icons-material/Email';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import TagIcon from '@mui/icons-material/Tag';
import { LoadingButton } from '@mui/lab';
import { Accordion, AccordionDetails, AccordionSummary, Alert, Box, Button, Card, CardActionArea, CardContent, Drawer, IconButton, Skeleton, Stack, TextField, ToggleButton, ToggleButtonGroup, Typography, useTheme } from "@mui/material";
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Field, Form, Formik } from 'formik';
import { TextField as FormikTextField } from 'formik-mui';
import { DateTime } from 'luxon';
import { enqueueSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo, useState } from 'react';
import * as Yup from 'yup';
import RegoPlate from '~/components/RegoPlate';
import { toHumanReadable } from '~/helpers/stringHelpers';
import { useJobBookingDialog } from '~/hooks/dialogs/useJobBookingDialog';
import { useConfirm } from '~/hooks/useConfirm';
import { bookingCalendarCanBookQuery, bookingFacilityCapacitiesQuery, bookingsQuery } from '~/loaders/bookingsLoader';
import { jobCategoriesQuery } from '~/loaders/jobCategoriesLoader';
import { updateBookingCapacity } from '~/requests/bookings';

const BookingsSideDrawer = ({
	date,
	facility,
	toggleDrawer
}) => {
	const theme = useTheme();

	const [tab, setTab] = useState('bookings');
	const queryclient = useQueryClient();

	const updateCapacityMutation = useMutation({
		mutationFn: ({ facility_uuid, ...data }) => updateBookingCapacity(facility_uuid, data),
		onSuccess: () => {
			queryclient.invalidateQueries({
				queryKey: ['bookings'],
			})
		},
	});


	return (
		<Drawer
			anchor="right"
			open={!!date}
			onClose={() => toggleDrawer(null)}
			sx={{
				'& .MuiDrawer-paper': {
					[theme.breakpoints.up('md')]: {
						marginTop: `${theme.headerHeight}px`,
					}
				}
			}}
		>
			{!!date && (
				<Box
					component="div"
					sx={{
						padding: theme.spacing(2),
						width: {
							xs: '100vw',
							sm: '90vw',
						},
						maxWidth: 400,
						position: 'relative'
					}}
				>

					<IconButton
						sx={{ position: 'absolute', top: 8, right: 8 }}
						onClick={() => toggleDrawer(null)}
					>
						<Close />
					</IconButton>
					<Stack direction="row" spacing={1} alignItems="center" sx={{ mb: 2 }}>
						<Typography variant="h3" gutterBottom>
							{date.toFormat('EEE, dd MMM yyyy')}
						</Typography>
					</Stack>

					<ToggleButtonGroup
						color="primary"
						size="small"
						fullWidth
						value={tab}
						exclusive
						sx={{ mb: 2 }}
						onChange={(e, val) => setTab(val)}
					>
						<ToggleButton value="bookings">Bookings</ToggleButton>
						<ToggleButton value="slots">Slots</ToggleButton>
					</ToggleButtonGroup>

					{tab === 'bookings' && (
						<BookingsPanel date={date} facility={facility} updateCapacityMutation={updateCapacityMutation} />
					)}

					{tab === 'slots' && (
						<SlotsPanel date={date} facility={facility} updateCapacityMutation={updateCapacityMutation} />
					)}

				</Box>
			)}
		</Drawer>
	);
}

const BookingsPanel = ({ date, facility, updateCapacityMutation }) => {

	const bookingDialog = useJobBookingDialog();

	const { data: bookingsData = [], isLoading: isLoadingBookings } = useQuery(bookingsQuery(
		date.startOf('day').toISO(),
		date.endOf('day').toISO(),
		[facility.id],
	));

	const { data: jobCategories } = useQuery(jobCategoriesQuery());

	const { data: dataCanBook = {}, isLoading: isLoadingCanBook } = useQuery(bookingCalendarCanBookQuery(facility.id, date.startOf('day').toISO(), date.endOf('day').toISO()));

	const canBook = useMemo(() => dataCanBook?.status === 'available', [dataCanBook]);
	const canIgnore = useMemo(() => dataCanBook?.status === 'at_capacity' && dataCanBook?.ignore_capacity, [dataCanBook]);

	const bookings = useMemo(() =>
		bookingsData.reduce((acc, booking) => {
			acc[booking.job_category.key].push({ ...booking });
			return acc;
		}, Object.fromEntries(jobCategories.map(c => [c.key, []]))),
		[bookingsData, jobCategories]
	);

	const hasBookings = useMemo(() => Object.values(bookings).some(b => b.length > 0), [bookings]);

	return (
		<>
			<Stack spacing={2}>
				<div>
					{(!canBook && !canIgnore) && (
						<Alert severity={'warning'} sx={{ mb: 1 }}>
							{`This day is ${toHumanReadable(dataCanBook?.status).toLowerCase()}.`}
						</Alert>
					)}
					{canIgnore && (
						<Alert severity={'warning'}>
							{`This day is at capacity but you have permission to ignore capacity limits.`}
						</Alert>
					)}
				</div>

				<LoadingButton
					color="primary"
					variant="contained"
					size='large'
					fullWidth sx={{ mb: 2 }}
					startIcon={<Add />}
					loading={isLoadingCanBook}
					disabled={!canBook && !canIgnore}
					onClick={() => bookingDialog.open({ facilityId: facility.uuid, date: date.startOf('day') })}
				>
					Book Job
				</LoadingButton>
			</Stack>

			{isLoadingBookings && (
				<>
					<Skeleton variant="rectangular" height={30} />
					<Skeleton variant="rectangular" height={30} />
					<Skeleton variant="rectangular" height={30} />
				</>
			)}

			{hasBookings && (
				<>
					{Object.entries(bookings).map(([key, bookings]) => (
						<Accordion key={key} defaultExpanded={bookings.length > 0} disabled={bookings.length === 0} sx={{ mb: 1 }}>
							<AccordionSummary expandIcon={<ExpandMoreIcon />}>
								<Typography fontWeight="bold">
									{jobCategories.find(j => j.key === key).name} ({bookings.length})
								</Typography>
							</AccordionSummary>
							<AccordionDetails>
								<Stack spacing={1}>
									{bookings.map((booking, index) => (
										<Card key={index} variant="outlined">
											<CardActionArea onClick={() => bookingDialog.open({
												facilityId: booking.facility.uuid,
												date: DateTime.fromISO(booking.booking_datetime),
												booking
											})}>
												<CardContent>
													<Stack direction="row" justifyContent="space-between">
														<Typography fontWeight="bold">{booking.submission.summary.customer}</Typography>
														<RegoPlate text={booking.submission.summary.rego} small />
													</Stack>

													<Stack direction="row" alignItems="center" spacing={1} mt={0.5}>
														<TagIcon fontSize="small" />
														<Typography variant="body2">{booking.submission.summary.claim_number}</Typography>
													</Stack>
													<Stack direction="row" alignItems="center" spacing={1} mt={0.5}>
														<PhoneIphoneIcon fontSize="small" />
														<Typography variant="body2">{booking.submission.summary.customer_phone}</Typography>
													</Stack>
													<Stack direction="row" alignItems="center" spacing={1}>
														<EmailIcon fontSize="small" />
														<Typography variant="body2">{booking.submission.summary.customer_email}</Typography>
													</Stack>
													<Stack direction="row" alignItems="center" spacing={1}>
														<DirectionsCarFilledIcon fontSize="small" />
														<Typography variant="body2">{booking.submission.summary.vehicle}</Typography>
													</Stack>
												</CardContent>
											</CardActionArea>
										</Card>
									))}
								</Stack>
							</AccordionDetails>
						</Accordion>
					))}
				</>
			)}

			{!hasBookings && (
				<Typography variant="body2" sx={{ mt: 2 }} color="text.secondary" align="center">
					No Bookings
				</Typography>
			)}
		</>
	)
};


const schema = Yup.object().shape({
	capacities: Yup.array().of(Yup.object().shape({
		slots: Yup.number().integer("Must be a whole number").min(0, "Must be greater than 0").required("Must be a whole number"),
	})),
});

const SlotsPanel = ({ date, facility, updateCapacityMutation }) => {
	const [block, setBlock] = useState(null);
	const { openConfirm } = useConfirm();

	const [fromDate, toDate] = useMemo(() => [date.startOf('day').toISO(), date.endOf('day').toISO()], [date]);

	const { data: capacitiesData = {}, isLoading: capacitiesLoading, isFetched } = useQuery(bookingFacilityCapacitiesQuery(facility?.uuid, fromDate, toDate));
	const { data: slotCategories, isLoading: slotsLoading } = useQuery(jobCategoriesQuery());

	const capacity = useMemo(
		() => Object.values(capacitiesData)[0]?.capacity || slotCategories.map(cat => ({
			job_category_id: cat.id,
			slots: '',
			job_category: cat
		})),
		[capacitiesData, slotCategories]);

	useEffect(() => {
		if (capacitiesData) {
			setBlock(Object.values(capacitiesData)[0]?.block?.[0]);
		}
	}, [capacitiesData])

	const assignSlots = useCallback((values, { setSubmitting }) => {
		// Assign slots
		setSubmitting(true);
		updateCapacityMutation.mutate({
			facility_uuid: facility.uuid,
			days: {
				[fromDate]: {
					block: null,
					slots: values.capacities.map(capacity => ({
						job_category_id: capacity.job_category.id,
						slots: capacity.slots
					}))
				},
			}
		}, {
			onSuccess: () => {
				enqueueSnackbar("Slots assigned successfully");
			},
			onError: (error) => enqueueSnackbar(error.message, { variant: 'error' }),
			onSettled: () => setSubmitting(false)
		});
		console.log(values.capacities);
	}, []);

	const handleUnblockDay = useCallback(() => {
		updateCapacityMutation.mutate({
			facility_uuid: facility.uuid,
			days: {
				[fromDate]: {
					block: null,
					slots: []
				},
			}
		}, {
			onSuccess: () => {
				setBlock(null);
				enqueueSnackbar("Day unblocked successfully");
			},
			onError: (error) => enqueueSnackbar(error.message, { variant: 'error' })
		});
	}, []);

	const handleClearSlots = useCallback(() => {
		openConfirm({
			type: 'question',
			title: 'Clear Slots',
			message: <span>Are you sure you want clear the slots for <span style={{ fontWeight: 'bold' }}>{date.toFormat('EEE, dd MMM yyyy')}</span>?</span>,
			onConfirm: (close) => {
				updateCapacityMutation.mutate({
					facility_uuid: facility.uuid,
					days: {
						[fromDate]: {
							block: null,
							slots: []
						},
					}
				}, {
					onSuccess: () => {
						enqueueSnackbar("Slots cleared successfully");
						close();
					},
					onError: (error) => enqueueSnackbar(error.message, { variant: 'error' })
				});
			},
			onCancel: (close) => close()
		});
	}, [openConfirm]);

	const handleBlockDay = useCallback(() => {
		openConfirm({
			type: 'info',
			title: 'Block Day',
			message: <span>To block <span style={{ fontWeight: 'bold' }}>{date.toFormat('EEE, dd MMM yyyy')}</span>, type in a reason below:</span>,
			cancelButtonText: 'Cancel',
			InputComponent: TextField,
			inputProps: { label: "Reason for block" },
			onConfirm: (close, value) => {
				updateCapacityMutation.mutate({
					facility_uuid: facility.uuid,
					days: {
						[fromDate]: {
							block: value,
							slots: []
						},
					}
				}, {
					onSuccess: () => {
						enqueueSnackbar("Day blocked successfully");
						setBlock({
							reason: value
						});
						close();
					},
					onError: (error) => enqueueSnackbar(error.message, { variant: 'error' })
				});
			},
			onCancel: (close) => close()
		});
	}, [openConfirm]);

	return (
		<Formik key={isFetched ? 'fetched' : 'not'} initialValues={{ capacities: capacity }} validationSchema={schema} onSubmit={assignSlots}>
			{({ handleSubmit, isSubmitting, values }) => (
				<Form>
					<Stack spacing={1} mb={3} position="relative">
						{(capacitiesLoading || slotsLoading) && (
							<>
								<Skeleton variant="rectangular" height={30} />
								<Skeleton variant="rectangular" height={30} />
								<Skeleton variant="rectangular" height={30} />
							</>
						)}
						{!(capacitiesLoading || slotsLoading) && (
							<>
								{values.capacities.map((capacity, index) => (
									<Box display="flex" alignItems="center" justifyContent="space-between">
										<Typography>{capacity.job_category.name}</Typography>
										<Field
											key={index}
											name={`capacities.${index}.slots`}
											component={FormikTextField}
											variant="standard"
											type="number"
											disabled={!!block || isSubmitting}
											style={{ width: '40%' }}
											InputProps={{ inputProps: { min: 0 } }}
										/>
									</Box>
								))}
								{block && (
									<div className="content-overlay">
										<Typography variant="body2">DAY BLOCKED:</Typography>
										<div style={{ fontWeight: 'bold' }}>{block.reason}</div>
									</div>
								)}
							</>
						)}
					</Stack>

					<LoadingButton color="primary" variant="contained" fullWidth loading={isSubmitting} disabled={!!block} sx={{ mb: 2 }} onClick={() => handleSubmit()}>
						Assign Slots
					</LoadingButton>

					<Stack direction="row" spacing={1}>
						<Button color="secondary" variant="outlined" fullWidth disabled={!!block || isSubmitting} onClick={handleClearSlots}>
							Clear Slots
						</Button>
						{block ? (
							<Button color="secondary" variant="contained" fullWidth onClick={handleUnblockDay} disabled={isSubmitting}>
								Unblock Day
							</Button>
						) : (
							<Button color="secondary" variant="outlined" fullWidth onClick={handleBlockDay} disabled={isSubmitting}>
								Block Day
							</Button>
						)}
					</Stack>
				</Form>
			)}
		</Formik>
	);
}

export default BookingsSideDrawer;