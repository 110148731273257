import { Close } from '@mui/icons-material';
import { Box, Button, Card, Chip, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Stack, Step, StepButton, Stepper, Tooltip, Typography } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Field, Form, Formik } from 'formik';
import { DateTime } from 'luxon';
import { enqueueSnackbar } from 'notistack';
import { useCallback, useMemo, useRef, useState } from 'react';
import * as Yup from 'yup';
import FacilityDropdown from '~/components/Dropdowns/FacilityDropdown';
import FormikDateRangePicker from '~/components/formik-mui/FormikDateRangePicker';
import Loader from '~/components/Loader';
import useBookingSlotsDialog from '~/hooks/useBookingSlotsDialog';
import { useFilters } from '~/hooks/useFilters';
import { jobCategoriesQuery } from '~/loaders/jobCategoriesLoader';
import { BookingSlotsDialogProvider } from '~/providers/dialogs/BookingSlotsDialogProvider';
import { updateBookingCapacity } from '~/requests/bookings';
import BookingSlotAssignDialog from './BookingSlotAssignDialog';
import { bookingCalendarCapacityQuery } from '~/loaders/bookingsLoader';
import { DateRangePickerDay } from '@mui/x-date-pickers-pro';

const wizardSteps = ['Choose Facility & Dates', 'Manage Calendar'];

const yupSchema = Yup.object().shape({
	facility: Yup.object().required('Facility is required'),
	dateRange: Yup.array().required('Start and end dates are required')
		.test('valid-dates', 'Start and end dates are required', (value) => {
			if (value.length !== 2) return false;
			const [start, end] = value;
			return DateTime.fromISO(start).isValid && DateTime.fromISO(end).isValid;
		})
		.test('valid-range', 'End date must be after start date', ([start, end]) => {
			if (!start || !end) return true;
			return new Date(end) > new Date(start);
		}),
});

const BookingCalendarManagementDialog = ({ onClose }) => {
	const queryClient = useQueryClient();

	const updateCapacityMutation = useMutation({
		mutationFn: ({ facility_uuid, ...data }) => updateBookingCapacity(facility_uuid, data),
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: ['bookings'],
			});
		},
	});

	const handleSave = useCallback((close, facility, data) => {
		updateCapacityMutation.mutate({
			facility_uuid: facility.uuid,
			days: data
		}, {
			onSuccess: () => {
				enqueueSnackbar("Booking slots updated successfully");
				close();
			},
			onError: (error) => enqueueSnackbar(error.message, { variant: 'error' })
		});
	}, []);

	return (
		<BookingSlotsDialogProvider>
			<BookingCalendarManagementDialogContent onClose={onClose} onSave={handleSave} />
		</BookingSlotsDialogProvider>
	);
};

const shortcutsItems = [
	{
		label: 'Next Month',
		getValue: () => {
			const today = DateTime.now().startOf('day');
			// Get the first day of next month and adjust to Monday (if needed)
			const startOfNextMonth = today.plus({ months: 1 }).startOf('month');
			const mondayStart = startOfNextMonth.weekday === 1
				? startOfNextMonth
				: startOfNextMonth.minus({ days: startOfNextMonth.weekday - 1 });
			// Get last day of next month and adjust to Sunday (if needed)
			const endOfNextMonth = today.plus({ months: 1 }).endOf('month');
			const sundayEnd = endOfNextMonth.weekday === 7
				? endOfNextMonth
				: endOfNextMonth.plus({ days: 7 - endOfNextMonth.weekday });
			return [mondayStart, sundayEnd];
		},
	},
	{
		label: 'Next 4 Weeks',
		getValue: () => {
			const now = DateTime.now().startOf('day');
			// Determine offset: if today is Monday, jump to next Monday; otherwise get days until next Monday
			const offset = now.weekday === 1 ? 7 : (8 - now.weekday);
			const start = now.plus({ days: offset }).startOf('week');
			const end = start.plus({ weeks: 4 }).endOf('week');
			return [start, end];
		},
	},
	{
		label: 'Next 8 Weeks',
		getValue: () => {
			const now = DateTime.now().startOf('day');
			const offset = now.weekday === 1 ? 7 : (8 - now.weekday);
			const start = now.plus({ days: offset }).startOf('week');
			const end = start.plus({ weeks: 8 }).endOf('week');
			return [start, end];
		},
	},
];

const BookingCalendarManagementDialogContent = ({ onClose, onSave }) => {
	const { setSelectedFacility, setDateRange, clearSelection, modifiedDays, selectedFacility } = useBookingSlotsDialog();
	const [currentStep, setCurrentStep] = useState(0);
	const formikRef = useRef(null);

	const handleNextStep = () => {
		switch (currentStep) {
			case 0:
				formikRef?.current?.submitForm();
				return;
			case 1:
				onSave(onClose);
				break;
		}
		setCurrentStep(currentStep + 1);
	}

	const handleBackStep = () => {
		if (currentStep === 1) clearSelection();
		setCurrentStep(currentStep - 1);
	}

	const handleSubmit = (values) => {
		setSelectedFacility(values.facility);
		setDateRange(values.dateRange);
		setCurrentStep(currentStep + 1);
	}

	const handleFinish = () => {
		onSave(onClose, selectedFacility, modifiedDays);
	}

	return (
		<Dialog open={true} fullWidth maxWidth="md">
			<DialogTitle display="flex" alignItems="center" justifyContent="space-between">
				<div>Booking Slot Assignment</div>
				<IconButton aria-label="close" onClick={onClose} edge="end">
					<Close />
				</IconButton>
			</DialogTitle>

			<DialogContent sx={{ pb: 5 }}>
				<Stepper activeStep={currentStep} sx={{ mb: 5 }}>
					{wizardSteps.map((label, index) => (
						<Step key={index} completed={currentStep >= index}>
							<StepButton color="inherit" disabled={index > currentStep} onClick={() => setCurrentStep(index)}>
								{label}
							</StepButton>
						</Step>
					))}
				</Stepper>

				{currentStep === 0 && (
					<Step1Content formikRef={formikRef} onSubmit={handleSubmit} />
				)}

				{currentStep === 1 && (
					<Step2Content />
				)}

			</DialogContent>
			<DialogActions sx={{ justifyContent: 'space-between' }}>
				<Button color="secondary" onClick={onClose}>
					Cancel
				</Button>
				<Stack direction="row" spacing={1}>
					{currentStep > 0 && (
						<Button color="primary" variant="outlined" onClick={handleBackStep}>
							Back
						</Button>
					)}
					{currentStep < wizardSteps.length - 1 && (
						<Button color="primary" variant="contained" onClick={handleNextStep}>
							Next
						</Button>
					)}
					{currentStep === wizardSteps.length - 1 && (
						<Button color="primary" variant="contained" disabled={Object.keys(modifiedDays).length === 0} onClick={handleFinish}>
							Save Changes
						</Button>
					)}
				</Stack>
			</DialogActions>
		</Dialog>
	);
}

const Step1Content = ({ formikRef, onSubmit }) => {
	const { filters } = useFilters();
	const { selectedFacility, dateRange, setSelectedFacility } = useBookingSlotsDialog();
	const initialFacility = selectedFacility || filters.facilities?.[0] || null;

	const [calendarViewDates, setCalendarViewDates] = useState([DateTime.now().startOf('month'), DateTime.now().endOf('month').plus({ months: 1 })]);

	const { data: capacityData = {}, isLoading: capacityLoading } = useQuery(bookingCalendarCapacityQuery(initialFacility?.id || selectedFacility?.id, calendarViewDates[0].toISO(), calendarViewDates[1].toISO()));

	const CustomDayPicker = useCallback((props) => {
		const { capacityData = {}, day, ...other } = props;

		const hasCapacity = capacityData[day.toIso8601()]?.has_capacity;
		const hasBlock = capacityData[day.toIso8601()]?.has_block;

		return (
			<DateRangePickerDay
				{...other}
				day={day}
				sx={{
					position: 'relative',
					...(hasCapacity && {
						'.MuiPickersDay-root:not(.MuiDateRangePickerDay-dayInsideRangeInterval)': {
							border: '1px solid',
							borderColor: 'grey.300',
							fontWeight: 'bold'
						}
					}),
					...(hasBlock && {
						'.MuiPickersDay-root:not(.MuiPickersDay-hiddenDaySpacingFiller)': {
							'&::after': {
								content: '""',
								position: 'absolute',
								top: 0,
								left: '50%',
								borderRadius: '50%',
								width: '1px',
								rotate: '-45deg',
								height: '100%',
								backgroundColor: 'grey.500',
							}
						}
					}),
					'.MuiPickersDay-today:not(.Mui-selected)': {
						borderWidth: '2px',
					}
				}}
			/>
		);
	}, []);

	return (
		<Formik
			innerRef={formikRef}
			initialValues={{ facility: initialFacility, dateRange }}
			validationSchema={yupSchema}
			onSubmit={onSubmit}
		>
			{({ setFieldValue }) => (
				<Form>
					<Stack gap={2} alignItems="center" maxWidth={400} mx="auto">
						<Field name="facility">
							{({ field, meta }) => (
								<FacilityDropdown
									{...field}
									onChange={(name, value) => {
										setSelectedFacility(value);
										setFieldValue(name, value);
									}}
									value={field.value?.id}
									formikMeta={meta}
									fullWidth
									returnObject
									showNone={false}
								/>
							)}
						</Field>
						<FormikDateRangePicker
							name="dateRange"
							label="Date Range"
							showDaysOutsideCurrentMonth
							displayWeekNumber
							loading={capacityLoading}
							slots={{
								day: CustomDayPicker,
							}}
							slotProps={{
								day: {
									capacityData
								},
								shortcuts: {
									items: shortcutsItems,
								},
								actionBar: { actions: [] },
							}}
							onMonthChange={date => setCalendarViewDates([date.startOf('month'), date.endOf('month').plus({ months: 1 })])}

						/>
					</Stack>
				</Form>
			)}
		</Formik>
	);
}

const Step2Content = () => {
	const hook = useBookingSlotsDialog();
	const { selectedFacility, selectedDays, setSelectedDays, weeks, fromDate, capacities, capacitiesLoading } = hook;

	const [isDragging, setIsDragging] = useState(false);
	const [isDragAdding, setIsDragAdding] = useState(false);

	const { data: slotCategories, isLoading } = useQuery(jobCategoriesQuery());

	const handleMouseDown = (day, selected) => {
		setIsDragging(true);
		setIsDragAdding(!selected);
		toggleSelectedDay(day);
	};
	const handleMouseUp = () => setIsDragging(false);
	const handleMouseEnter = (day, selected) => {
		if (isDragging) {
			if (isDragAdding && !selected) addSelectedDay(day);
			else if (!isDragAdding && selected) removeSelectedDay(day);
		}
	};

	const toggleRange = useCallback((days) => {
		setSelectedDays(curDays => {
			const updatedDays = new Set(curDays);
			const allSelected = days.every(day => updatedDays.has(day.toISO()));

			if (allSelected) {
				// Remove days from selection
				days.forEach(day => updatedDays.delete(day.toISO()));
			} else {
				// Add days to selection
				days.forEach(day => updatedDays.add(day.toISO()));
			}

			return updatedDays;
		});
	}, []);

	const addSelectedDay = useCallback((day) => (
		setSelectedDays(curDays => {
			const updatedDays = new Set(curDays);
			updatedDays.add(day.toISO());
			return updatedDays;
		})
	), [setSelectedDays]);

	const removeSelectedDay = useCallback((day) => (
		setSelectedDays(curDays => {
			const updatedDays = new Set(curDays);
			updatedDays.delete(day.toISO());
			return updatedDays;
		})
	), [setSelectedDays]);

	const toggleSelectedDay = useCallback((day) => (
		toggleRange([day])
	), [toggleRange]);

	const toggleDaysByIndex = useCallback((colIndex) => {
		const daysInScope = weeks
			.map(week => week.days[(colIndex) % 7])
			.filter(day => day !== null);
		toggleRange(daysInScope);
	}, [weeks, toggleRange]);

	const toggleDaysByWeek = useCallback((weekNumber) => {
		const daysInWeek = weeks.find(week => week.weekNumber === weekNumber)?.days || [];
		const filteredDays = daysInWeek.filter((day, index) => day !== null && index !== 5 && index !== 6);
		toggleRange(filteredDays);
	}, [weeks, toggleRange]);

	const selectionHasModifications = useMemo(() => {
		const modifiedDays = hook.modifiedDays;
		return [...selectedDays].filter(day => modifiedDays[day]).length > 0;
	}, [hook.modifiedDays, selectedDays]);

	return (
		<>
			<Typography variant="h3" gutterBottom align="center" mb={3}>
				{selectedFacility.name}
			</Typography>

			<Box display="grid" gridTemplateColumns="40px repeat(7, 1fr)">
				<div></div>
				{['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'].map((day, i) => (
					<Button
						key={i}
						color="inherit"
						variant="text"
						size="medium"
						sx={{ flex: 1, minWidth: 'auto' }}
						fullWidth
						// Adjust index for proper underlying day column (Monday is index 1 in week.days)
						onClick={() => toggleDaysByIndex(i)}
					>
						{day}
					</Button>
				))}
			</Box>
			{capacitiesLoading && (
				<Loader />
			)}
			<Stack spacing={1} width="100%">
				{weeks.map((week, index) => (
					<Card key={index} sx={{
						display: 'grid',
						gridTemplateColumns: '40px repeat(7, 1fr)',
						width: '100%',
						minHeight: 50,
						alignItems: 'stretch',
						borderRadius: 3
					}}>
						<Button
							color="primary" variant="text" size="small"
							sx={{
								minWidth: 40,
								p: 0,
								borderRadius: 0,
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
							}}
							onClick={() => toggleDaysByWeek(week.weekNumber)}
						>
							W{week.weekNumber}
						</Button>
						{week.days.map((day, dayIndex) => (
							<BookingCalendarDay
								key={dayIndex}
								date={day}
								showMonth={day ? (day.equals(fromDate) || day.day === 1) : false}
								selected={day ? selectedDays.has(day.toISO()) : false}
								isDragging={isDragging}
								onMouseDown={handleMouseDown}
								onMouseUp={handleMouseUp}
								onMouseEnter={handleMouseEnter}
							/>
						))}
					</Card>
				))}
			</Stack>

			<Stack mt={3} direction="row" gap={1}>
				<Button color="primary" disabled={selectedDays.size === 0} variant="contained" fullWidth onClick={hook.handleAssign}>
					Assign Slots
				</Button>
				<Button color="primary" disabled={selectedDays.size === 0} variant="contained" fullWidth onClick={hook.handleClear}>
					Clear
				</Button>
				<Button color="primary" disabled={selectedDays.size === 0} variant="contained" fullWidth onClick={hook.handleBlock}>
					Block
				</Button>
				<Button color="secondary" disabled={!selectionHasModifications} variant="contained" fullWidth sx={{ ml: 3 }} onClick={hook.handleUndo}>
					Revert
				</Button>
			</Stack>

			{hook.assignDialogOpen && (
				<BookingSlotAssignDialog capacityData={[]} slotCategories={slotCategories} onClose={() => hook.setAssignDialogOpen(false)} onSave={hook.assignSlots} />
			)}
		</>
	);
}

const BookingCalendarDay = ({ date, showMonth, selected, isDragging, onMouseDown, onMouseUp, onMouseEnter }) => {
	const { calendarData } = useBookingSlotsDialog();

	const data = useMemo(() => (
		date ? calendarData[date.toISO()] || {} : {}
	), [calendarData, date]);

	return (
		<Box
			px={1} py={0.5}
			borderLeft={theme => `1px solid ${theme.palette.divider}`}
			bgcolor={selected ? "primary.light" : (data.block ? "grey.200" : "white")}
			sx={!date ? {} : {
				overflow: 'hidden',
				cursor: isDragging ? 'cell' : 'pointer',
				userSelect: 'none',
				'&:hover': { backgroundColor: selected ? "primary.light2" : "grey.100" }
			}}
			className={date ? "" : "background-diagonal-stripes"}
			onMouseDown={() => date && onMouseDown(date, selected)}
			onMouseUp={() => date && onMouseUp()}
			onMouseEnter={() => date && onMouseEnter(date, selected)}
		>
			{date && (
				<Box fontSize="75%">
					<div>
						{showMonth
							? <span style={{ fontWeight: 'bold' }}>{date.toFormat('MMM d')}</span>
							: date.toFormat('d')
						}
					</div>
					<div style={{ fontWeight: 'bold' }}>
						{data.block ? (
							<div className="text-truncate">{data.block}</div>
						) : data.slots ? (
							<Stack direction="row" spacing={0.5} justifyContent="center">
								{data.slots.map((slot, index) => (
									<Tooltip key={index} title={slot.name}>
										<Chip size="small" label={slot.slots} />
									</Tooltip>
								))}
							</Stack>
						) : null}
					</div>
				</Box>
			)}
		</Box>
	)
}

export default BookingCalendarManagementDialog