import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import * as Sentry from "@sentry/react";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { SnackbarProvider, closeSnackbar } from 'notistack';
import { PermissionProvider } from '~/providers/PermissionProvider';
import { ConfirmDialogProvider } from '~/providers/dialogs/ConfirmationDialogProvider';
import themeGenerator from '~/themeGenerator';
import { AuthProvider } from './providers/AuthProvider';
import { LayoutProvider } from './providers/LayoutProvider';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import Router from './Router';
import './assets/styles/generic.scss';
import { GlobalStateProvider } from './providers/GlobalStateProvider';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import './helpers/luxonExtensions';

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			staleTime: 1000 * 10,
		},
	},
});

function App() {
	return (
		<Sentry.ErrorBoundary fallback={<p>An error has occurred</p>} showDialog>
			<LocalizationProvider dateAdapter={AdapterLuxon}>
				<GlobalStateProvider>
					<LayoutProvider>
						<ThemeProvider theme={themeGenerator()}>
							<CssBaseline />
							<QueryClientProvider client={queryClient}>
								<AuthProvider>
									<PermissionProvider>
										<ConfirmDialogProvider>
											<SnackbarProvider anchorOrigin={{
												vertical: "top",
												horizontal: "right"
											}}
												variant={'success'}
												maxSnack={3}
												action={(snackbarId) => (
													<IconButton
														size="small"
														aria-label="close"
														color="inherit"
														onClick={() => closeSnackbar(snackbarId)}
													>
														<CloseIcon fontSize="small" />
													</IconButton>
												)}
											>

												<Router />
												<ReactQueryDevtools buttonPosition="bottom-left" />

											</SnackbarProvider>
										</ConfirmDialogProvider>
									</PermissionProvider>
								</AuthProvider>
							</QueryClientProvider>
						</ThemeProvider>
					</LayoutProvider>
				</GlobalStateProvider>
			</LocalizationProvider>
		</Sentry.ErrorBoundary>
	);
}

export default App;
