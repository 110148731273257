import React, { memo, useMemo } from "react";
import { Box, Drawer, List, ListItem, ListItemButton, ListItemText, Toolbar, Typography, useTheme, Tooltip, Badge } from "@mui/material";
import { useForm } from "../../hooks/useForm";
import { NavLink } from "react-router-dom";
import { useGate } from "~/hooks/useGate";

const FormSubMenu = memo(({
	isMobile = false,
	isOpen = true,
	drawerWidth
}) => {

	const { menuItems, additionalMenuItems, isMenuItemDisabled, isMenuItemError, submission } = useForm();
	const theme = useTheme();
	const { gate } = useGate();

	const additionalItems = useMemo(() => additionalMenuItems.filter(item => item.allowed?.(submission, gate) || false), [additionalMenuItems, submission, gate]);

	return (
		<>
			<Drawer
				variant="persistent"
				anchor="left"
				open={isOpen}
				sx={{
					width: drawerWidth,
					flexShrink: 0,
					[`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
				}}
			>
				<Toolbar />
				<Box>
					<List disablePadding>

						{menuItems.map((item) => {
							const isError = isMenuItemError(item.key);
							return (
								<ListItem
									key={item.key}
									style={{
										borderWidth: 1,
										borderColor: isError ? theme.palette.error.main : "lightgray",
										borderStyle: "solid",
										aspectRatio: submission?.is_completed ? 1.2 : 1,
									}}
									disablePadding
								>
									<Tooltip title={item.title} placement="right">
										<ListItemButton
											component={NavLink}
											disabled={isMenuItemDisabled(item.key)}
											to={`../${item.url}`}
											// use style attr on purpose due to isActive from react router
											style={({ isActive }) => {
												return {
													minHeight: '100%',
													backgroundColor: isError ? "rgba(255, 0, 0, 0.1)" : (isActive ? "rgba(57, 73, 171, 0.4)" : ""),
													color: isError ? theme.palette.error.main : (isActive ? theme.palette.primary.main : "inherit"),
													fontWeight: isActive ? 500 : 400,
													padding: 0,
													display: 'flex',
													justifyContent: 'center',
													alignItems: 'center',
												};
											}}
										>
											<ListItemText sx={{ textAlign: "center", fontWeight: 'inherit' }}>
												<Typography sx={{ fontWeight: 'inherit', fontSize: isMobile ? '75%' : '1rem' }}>{item.label}</Typography>
											</ListItemText>

										</ListItemButton>
									</Tooltip>
								</ListItem>
							)
						})}

						{additionalItems.length > 0 && (
							<Box sx={{
								height: (theme) => theme.spacing(2),
							}}>

							</Box>
						)}

						{additionalItems.map((item) => (
							<ListItem
								key={item.key}
								style={{
									borderWidth: 1,
									borderColor: "lightgray",
									borderStyle: "solid",
									aspectRatio: 1,
								}}
								disablePadding
							>
								<Tooltip title={item.title} placement="right">
									<ListItemButton
										component={NavLink}
										disabled={isMenuItemDisabled(item.key)}
										to={`../${item.url}`}
										style={({ isActive }) => {
											return {
												minHeight: '100%',
												backgroundColor: isActive ? "rgba(57, 73, 171, 0.4)" : "",
												color: isActive ? theme.palette.primary.main : "inherit",
												fontWeight: isActive ? 500 : 400,
												padding: 0,
												display: 'flex',
												justifyContent: 'center',
												alignItems: 'center',
											};
										}}
									>
										<ListItemText sx={{ textAlign: "center", fontWeight: 'inherit' }}>
											<Badge color="info" variant="dot" badgeContent=" " invisible={!item.badge}>
												<Typography sx={{ fontWeight: 'inherit', fontSize: isMobile ? '75%' : '1rem' }}>{item.label}</Typography>
											</Badge>
										</ListItemText>
									</ListItemButton>
								</Tooltip>
							</ListItem>
						)
						)}
					</List>
				</Box>
			</Drawer>
		</>
	);
});
FormSubMenu.displayName = 'FormSubMenu';

export default FormSubMenu;