import { TextField } from '@mui/material';
import { useContext, useMemo, useState } from 'react';
import { __BookingSlotsDialogContext } from '~/providers/dialogs/BookingSlotsDialogProvider';
import { useConfirm } from './useConfirm';

const useBookingSlotsDialog = () => {
	const context = useContext(__BookingSlotsDialogContext);
	const { dateRange, selectedDays, setSelectedDays, setModifiedDays, selectedFacility } = context;

	const [assignDialogOpen, setAssignDialogOpen] = useState(false);

	const { openConfirm } = useConfirm();

	const fromDate = useMemo(() => dateRange[0], [dateRange]);
	const toDate = useMemo(() => dateRange[1], [dateRange]);

	const weeks = useMemo(() => {
		// For Monday-first week, subtract (weekday - 1)
		let startOfWeek = fromDate.minus({ days: fromDate.weekday - 1 });

		let weeks = [];
		while (startOfWeek <= toDate) {
			const week = [...Array(7)].reduce((acc, _, i) => {
				const day = startOfWeek.plus({ days: i });
				if (day < fromDate || day > toDate) {
					acc.push(null); // date out of range
				} else {
					acc.push(day);
				}
				return acc;
			}, []);

			weeks.push({
				weekNumber: startOfWeek.weekNumber,
				days: week,
			});

			startOfWeek = startOfWeek.plus({ weeks: 1 });
		}
		return weeks;
	}, [fromDate, toDate]);


	const clearSelection = () => setSelectedDays(new Set());

	const assignSlots = (values) => {
		setModifiedDays(prevState => {
			const updatedDays = { ...prevState };

			selectedDays.forEach(day => {
				updatedDays[day] = {
					slots: values.capacity,
					block: null
				};
			});
			return updatedDays;
		});
		setAssignDialogOpen(false);
		clearSelection();
	};

	const handleAssign = () => {
		// get input data
		setAssignDialogOpen(true);
	};

	const handleClear = () => {
		setModifiedDays(prevState => {
			const updatedDays = { ...prevState };
			selectedDays.forEach(day => {
				updatedDays[day] = null;
			});
			return updatedDays;
		});
		clearSelection();
	};

	const handleBlock = () => {
		openConfirm({
			type: 'info',
			title: 'Block Day',
			message: <span>To block the selected day/s, type in a reason below:</span>,
			cancelButtonText: 'Cancel',
			InputComponent: TextField,
			required: true,
			inputProps: { label: "Reason for block" },
			onConfirm: (close, value) => {
				setModifiedDays(prevState => {
					const updatedDays = { ...prevState };
					selectedDays.forEach(day => {
						updatedDays[day] = { block: value, slots: [] };
					});
					return updatedDays;
				});
				clearSelection();
				close();
			},
			onCancel: (close) => close()
		});
	};

	const handleUndo = () => {
		setModifiedDays(prevState => {
			const updatedDays = { ...prevState };
			selectedDays.forEach(day => {
				delete updatedDays[day];
			});
			return updatedDays;
		});
		clearSelection();
	};

	return ({
		...context,
		weeks,
		fromDate,
		toDate,
		assignDialogOpen,
		setAssignDialogOpen,
		clearSelection,
		assignSlots,
		handleAssign,
		handleClear,
		handleBlock,
		handleUndo,
	})
}

export default useBookingSlotsDialog