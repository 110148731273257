import { queryOptions } from "@tanstack/react-query";
import { queryFunctionHelper } from "~/helpers/queryHelper";
import { getBookingCalendarAvailability, getBookingCalendarCanBook, getBookingCalendarCapacity, getBookingCalendarNextAvailable, getBookingCapacitiesByFacility, getBookings, getBookingsCapacities } from "~/requests/bookings";

export const bookingCalendarAvailabilityQuery = (facility_uuid, jobCategoryId, startDate, endDate, params = {}) => queryOptions({
	queryKey: ['bookings', facility_uuid, 'calendar-availability', jobCategoryId, [startDate, endDate], params],
	queryFn: queryFunctionHelper(getBookingCalendarAvailability, facility_uuid, { job_category_id: jobCategoryId, start_date: startDate, end_date: endDate }),
	enabled: (!!facility_uuid && !!jobCategoryId && !!startDate && !!endDate)
});

export const bookingCalendarCapacityQuery = (facility_uuid, startDate, endDate, params = {}) => queryOptions({
	queryKey: ['bookings', facility_uuid, 'calendar-capacity', [startDate, endDate], params],
	queryFn: queryFunctionHelper(getBookingCalendarCapacity, facility_uuid, { start_date: startDate, end_date: endDate }),
	enabled: (!!facility_uuid && !!startDate && !!endDate)
});


export const bookingCalendarNextAvailableQuery = (facility_uuid, job_category_id, startDate, params = {}) => queryOptions({
	queryKey: ['bookings', facility_uuid, 'next-available', job_category_id, startDate, params],
	queryFn: queryFunctionHelper(getBookingCalendarNextAvailable, facility_uuid, { job_category_id: job_category_id, start_date: startDate, ...params }),
	enabled: (!!facility_uuid && !!job_category_id && !!startDate && !!startDate)
});

export const bookingCalendarCanBookQuery = (facility_uuid, startDate, endDate, params = {}) => queryOptions({
	queryKey: ['bookings', facility_uuid, 'can-book', [startDate, endDate], params],
	queryFn: queryFunctionHelper(getBookingCalendarCanBook, facility_uuid, { start_date: startDate, end_date: endDate }),
	enabled: (!!facility_uuid && !!startDate && !!endDate)
});

export const bookingFacilityCapacitiesQuery = (facility_uuid, startDate, endDate, params = {}) => queryOptions({
	queryKey: ['bookings', facility_uuid, 'capacities', [startDate, endDate], params],
	queryFn: queryFunctionHelper(getBookingCapacitiesByFacility, facility_uuid, { start_date: startDate, end_date: endDate }),
	enabled: !!facility_uuid
});

export const bookingCapacitiesQuery = (startDate, endDate, facility_uuids, params = {}) => queryOptions({
	queryKey: ['bookings', [startDate, endDate], 'capacities', facility_uuids, params],
	queryFn: queryFunctionHelper(getBookingsCapacities, startDate, endDate, facility_uuids),
	enabled: !!(startDate && endDate && facility_uuids.length),
});

export const bookingsQuery = (startDate, endDate, facility_uuids, params = {}) =>
	queryOptions({
		queryKey: ['bookings', [startDate, endDate], facility_uuids, params],
		queryFn: queryFunctionHelper(getBookings, startDate, endDate, facility_uuids, params),
		enabled: !!(startDate && endDate && facility_uuids.length),
		staleTime: 1000 * 30, // 30 seconds
		refetchInterval: 1000 * 60 * 5 // 5 minutes
	});
