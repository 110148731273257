import { Close } from '@mui/icons-material';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Skeleton, Stack, Typography } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-mui';
import { enqueueSnackbar } from 'notistack';
import { useCallback, useMemo } from 'react';
import * as Yup from 'yup';
import { jobCategoriesQuery } from '~/loaders/jobCategoriesLoader';
import { storeJobCategories } from '~/requests/jobCategories';

const validationSchema = Yup.object().shape({
	categories: Yup.array().of(
		Yup.object().shape({
			name: Yup.string().required('Category Name is required'),
			booking_default_capacity: Yup.number().min(0, 'Value must be 0 or more').required('Default Value is required'),
			repair_duration_days: Yup.number().min(0, 'Value must be 0 or more').nullable()
		})
	)
});

const BookingCategoriesDialog = ({ onClose }) => {
	const { data: slotCategories, refetch, isLoading } = useQuery(jobCategoriesQuery());
	const queryClient = useQueryClient();


	const updateMutation = useMutation({
		mutationFn: (data) => storeJobCategories(data),
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: ['bookings'],
			});
		},
	});


	const handleOnSubmit = useCallback((values, { setSubmitting }) => {
		const data = values.categories.map(v => ({
			id: v.id,
			booking_default_capacity: v.booking_default_capacity,
			repair_duration_days: v.repair_duration_days ?? null,
		}));

		setSubmitting(true);
		updateMutation.mutate({ categories: data }, {
			onSuccess: () => {
				onClose();
				enqueueSnackbar('Booking Slot Categories saved successfully');
				refetch();
			},
			onError: (error) => {
				enqueueSnackbar(error.response?.data?.message || 'Failed to save Booking Slot Categories', { variant: 'error' });
				setSubmitting(false);
			},
		});
	}, [onClose]);

	const initialValues = useMemo(() => ({
		categories: slotCategories || []
	}), [slotCategories]);

	return (
		<Dialog open={true}>
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={handleOnSubmit}
				enableReinitialize
			>
				{({ values, isSubmitting, submitForm }) => (
					<>

						<DialogTitle display="flex" alignItems="center" justifyContent="space-between">
							<div>Booking Slot Categories</div>
							<IconButton aria-label="close" onClick={onClose} edge="end">
								<Close />
							</IconButton>
						</DialogTitle>

						<DialogContent sx={{ pb: 2, minWidth: 375 }}>
							<Form>
								<Stack spacing={1} mb={2} position="relative">
									<Box display="flex" alignItems="center" gap={2}>
										<Typography variant="body2" width="100%">Category Name</Typography>
										<Typography variant="body2" width="100%">Default Slots</Typography>
										<Typography variant="body2" width="100%">Repair Duration (days)</Typography>
									</Box>
									{isLoading && (
										<>
											<Skeleton variant="rectangular" />
											<Skeleton variant="rectangular" />
											<Skeleton variant="rectangular" />
										</>
									)}
									{values.categories.map((category, index) => (
										<Box key={index} display="flex" alignItems="center" gap={2}>
											<Box width="100%">{category.name}</Box>
											<Field
												name={`categories.${index}.booking_default_capacity`}
												component={TextField}
												variant="standard"
												type="number"
												required
												fullWidth
												InputProps={{ inputProps: { min: 0 } }}
											/>
											<Field
												name={`categories.${index}.repair_duration_days`}
												component={TextField}
												variant="standard"
												type="number"
												fullWidth
												InputProps={{ inputProps: { min: 0 } }}
											/>
										</Box>
									))}
								</Stack>
							</Form>
						</DialogContent>
						<DialogActions>
							<Button color="secondary" onClick={onClose}>
								Cancel
							</Button>
							<Button color="primary" variant="contained" onClick={submitForm} disabled={isSubmitting}>
								Save
							</Button>
						</DialogActions>
					</>
				)}
			</Formik>
		</Dialog>
	)
}

export default BookingCategoriesDialog