import { Add, MoreVert } from "@mui/icons-material"
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth"
import { Box, Button, Card, Divider, Grid, IconButton, Menu, MenuItem, Skeleton, Stack, Typography } from "@mui/material"
import { useQueries, useQuery } from "@tanstack/react-query"
import { DateTime } from "luxon"
import { useCallback, useEffect, useMemo, useState } from "react"
import FilterBar from "~/components/FilterBar/FilterBar"
import FilterFacilitiesDropdown from "~/components/FilterBar/FilterFacilitiesDropdown"
import FilterMonthYearPicker from '~/components/FilterBar/FilterMonthYearPicker'
import FilterWorkProviderDropdown from "~/components/FilterBar/FilterWorkProviderDropdown"
import Loader from "~/components/Loader"
import Page from "~/components/Page"
import PageTitle from "~/components/PageTitle"
import { stringToColor } from "~/helpers/generalHelper"
import { useJobBookingDialog } from "~/hooks/dialogs/useJobBookingDialog"
import { useBookings } from "~/hooks/useBookings"
import { useFilters } from "~/hooks/useFilters"
import { bookingCapacitiesQuery, bookingsQuery } from "~/loaders/bookingsLoader"
import { facilitiesQuery } from "~/loaders/facilitiesLoader"
import { jobCategoriesQuery } from "~/loaders/jobCategoriesLoader"
import { BookingsProvider } from "~/providers/BookingsProvider"
import { FilterBarProvider } from "~/providers/FilterBarProvider"
import BookingCalendarManagementDialog from "./BookingCalendarManagementDialog"
import BookingCategoriesDialog from "./BookingCategoriesDialog"
import BookingsSideDrawer from "./BookingsSideDrawer"

const defaultFilters = ({
	selected_date: DateTime.now().startOf('month').toISODate(),
	is_deleted: null,
	is_external: null,
	workprovider_uuid: null,
	facilities: [],
});


const BookingsPage = () => {
	const [anchorEl, setAnchorEl] = useState(null);
	const [slotAssignDialogOpen, setSlotAssignDialogOpen] = useState(false);
	const [slotCategoriesDialogOpen, setSlotCategoriesDialogOpen] = useState(false);

	const bookingDialog = useJobBookingDialog();

	const { data: facilities } = useQuery(facilitiesQuery());

	const handleMenuItemClick = useCallback((action) => () => {
		if (action) action();
		setAnchorEl(null);
	}, []);

	useEffect(() => {
		if (anchorEl && slotCategoriesDialogOpen) {
			setAnchorEl(null);
		}
	}, [slotCategoriesDialogOpen]);

	const seralizeFilters = (filters) => ({
		selected_date: filters.selected_date,
		facilities: filters.facilities,
	});

	const unSeralizeFilters = (filters) => ({
		selected_date: DateTime.fromISO(filters.selected_date),
		facilities: filters.facilities
	});

	return (
		<Page title="Bookings">
			{facilities?.length > 0 ? (
				<FilterBarProvider
					defaults={defaultFilters}
					serialize={seralizeFilters}
					unserialize={unSeralizeFilters}
				>
					<Grid container marginBottom={3} spacing={2}>
						<Grid item xs={12} lg={3} xl={2}>
							<PageTitle gutterBottom={false} icon={<CalendarMonthIcon />} subtitle="Job booking calendar / availability">Bookings</PageTitle>
						</Grid>
						<Grid item xs={12} lg={9} xl={10}>
							<FilterBar>
								{({ filters, updateFilterValue, Wrap }) => {
									const selDate = DateTime.fromISO(filters.selected_date);
									return (
										<>
											<FilterMonthYearPicker name="selected_date" value={selDate} onChange={updateFilterValue} />
											<Divider orientation='vertical' flexItem />
											<Wrap minWidth={150} grow={1.5}>
												<FilterFacilitiesDropdown value={filters.facilities} multiselect onChange={updateFilterValue} />
											</Wrap>
											<Wrap minWidth={150} grow={1}>
												<FilterWorkProviderDropdown value={filters.workprovider_uuid} onChange={updateFilterValue} />
											</Wrap>
											<Box display="flex" flex={1} justifyContent="flex-end" gap={1}>
												<Button color="primary" variant="contained" size="medium" sx={{ borderRadius: '50vh', whiteSpace: 'nowrap' }} onClick={() => bookingDialog.open({
													facilityId: filters.facilities?.[0]?.uuid,
												})}>
													<Add /> Book Job
												</Button>
												<IconButton color="primary" size="medium" onClick={e => setAnchorEl(e.currentTarget)}>
													<MoreVert />
												</IconButton>

												<Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
													<MenuItem onClick={handleMenuItemClick(() => setSlotAssignDialogOpen(true))}>
														Manage Calendar
													</MenuItem>
													<MenuItem onClick={handleMenuItemClick(() => setSlotCategoriesDialogOpen(true))}>
														Slot Defaults
													</MenuItem>
												</Menu>
											</Box>
										</>
									);
								}}
							</FilterBar>
						</Grid>
					</Grid>

					<BookingCalendarWrapper />

					{slotAssignDialogOpen && (
						<BookingCalendarManagementDialog
							onClose={() => setSlotAssignDialogOpen(false)}
						/>
					)}

					{slotCategoriesDialogOpen && (
						<BookingCategoriesDialog
							onClose={() => setSlotCategoriesDialogOpen(false)}
						/>
					)}

				</FilterBarProvider>
			) : (
				<Loader />
			)}
		</Page>
	)
}

const BookingCalendarWrapper = () => {
	const { filters } = useFilters();
	const { data: facilities } = useQuery(facilitiesQuery());

	const selectedFacilities = useMemo(() => {
		if (!filters.facilities?.length) return [];
		return facilities.filter(facility => filters.facilities.some(uuid => uuid === facility.uuid));
	}, [filters.facilities, facilities]);

	return (
		<BookingsProvider monthDate={filters.selected_date} selectedFacilities={selectedFacilities}>
			<BookingCalendar />
		</BookingsProvider>
	);
}

const BookingCalendar = () => {
	const { weekStartDates, selectedDay, setSelectedDay, selectedFacilities } = useBookings();

	return (
		<>
			<Box overflow="auto">
				{!selectedFacilities.length && (
					<Typography variant="h4" align="center" color="textSecondary" mt={3}>
						Please select facilities to view bookings
					</Typography>
				)}

				<Stack spacing={2} mb={1}>
					{weekStartDates.map((startOfWeek, i) => (
						<BookingCalendarWeek key={i} startOfWeek={startOfWeek} />
					))}
				</Stack>
			</Box>

			<BookingsSideDrawer date={selectedDay?.date} facility={selectedDay?.facilty} toggleDrawer={setSelectedDay} />
		</>
	)
}

const BookingCalendarWeek = ({ startOfWeek }) => {
	const { selectedFacilities } = useBookings();

	const [
		{ data: bookings, isLoading: isLoadingBookings },
		{ data: capacties, isLoading: isLoadingCapacties },
		{ data: jobCategories, isLoading: isLoadingJobCategories }
	] = useQueries({
		queries: [
			bookingsQuery(
				startOfWeek.plus({ days: 1 }).toISO(), // start date shifted to Monday
				startOfWeek.plus({ days: 7 }).endOf('day').toISO(), // end date is Sunday
				selectedFacilities.map(f => f.uuid),
				{ grouped: true }
			),
			bookingCapacitiesQuery(
				startOfWeek.plus({ days: 1 }).toISO(),
				startOfWeek.plus({ days: 7 }).endOf('day').toISO(),
				selectedFacilities.map(f => f.uuid)
			),
			jobCategoriesQuery()
		]
	});

	const weekData = useMemo(() => {
		if (!jobCategories) return {};

		return selectedFacilities.reduce((acc, facility) => {
			acc[facility.uuid] = {
				data: [...Array(7)].reduce((acc2, _, i) => {
					// Calculate date starting on Monday instead of Sunday
					const date = startOfWeek.plus({ days: i + 1 }).startOf('day').setZone('utc').toIso8601();

					const defaultJobCategories = jobCategories?.reduce((jobAcc, job) => {
						jobAcc[job.key] = { bookings: 0, slots: 0 };
						return jobAcc;
					}, {});

					const capacityData = capacties?.[facility.uuid]?.[date] || {};
					const bookingData = bookings?.[facility.uuid]?.[date] || {};

					// Merge the real capacity data into the default structure
					Object.keys(capacityData.capacity || {}).forEach(jobKey => {
						defaultJobCategories[jobKey] = {
							bookings: bookingData[jobKey]?.length ?? 0,
							slots: capacityData.capacity[jobKey]?.slots ?? 0
						};
					});

					acc2[date] = {
						capacities: defaultJobCategories,
						block: capacityData.block?.[0] || null,
					};
					return acc2;
				}, {}),
				facility: facility
			};

			return acc;
		}, {});
	}, [selectedFacilities, startOfWeek, bookings, capacties, jobCategories]);

	if (isLoadingBookings || isLoadingCapacties || isLoadingJobCategories) {
		return (
			<Skeleton height={140} animation='wave' sx={{ mt: 0 }} />
		)
	} else {

		return (
			<Card sx={{ borderRadius: 3, minWidth: 'min-content' }}>
				{
					Object.entries(weekData).map(([facility_uuid, { data, facility }], i) => {
						// loop through each facility and render the week's data
						let weekTotals = {};
						const bgColour = stringToColor(facility.name);

						return (
							<Box key={i} display="flex" minWidth="min-content" alignItems="stretch" minHeight={100} borderTop={theme => i > 0 ? `1px solid ${theme.palette.divider}` : null}>
								<Box width={25} display="flex" justifyContent="center" alignItems="center" bgcolor={bgColour}>
									<Box
										width={100} color={theme => theme.palette.getContrastText(bgColour)} textAlign="center" lineHeight={1} fontSize="70%" position="absolute"
										className="text-truncate"
										sx={{ transform: 'rotate(-90deg)' }}
										title={facility.name}
									>
										{facility.name}
									</Box>
								</Box>

								<Box bgcolor="primary.light" width={50} display="flex" justifyContent="center" alignItems="center">
									<Typography variant="h4">
										W{startOfWeek.plus({ days: 1 }).weekNumber}
									</Typography>
								</Box>

								{[...Array(7)].map((_, i) => {
									// loop through each day starting on Monday and find data for that day
									const dayDate = startOfWeek.plus({ days: i + 1 });
									const dayData = data[dayDate.startOf('day').setZone('utc').toIso8601()] || {};

									const capacities = dayData.capacities || {}
									const block = dayData.block

									if (capacities) {
										weekTotals = Object.entries(capacities).reduce((acc, [key, value]) => {
											acc[key] = acc[key] || { bookings: 0, slots: 0 };
											acc[key].bookings += value.bookings;
											acc[key].slots += value.slots;
											return acc;
										}, weekTotals);
									}

									return (
										<BookingCalendarDay key={i} date={dayDate} facility={facility} data={capacities} block={block} />
									);
								})}

								<BookingCalendarDay key="totals" date={null} data={weekTotals} totals={true} facility={facility} />
							</Box>
						);
					})
				}
			</Card>
		);
	}

}

const BookingCalendarDay = ({ date, data = {}, facility, totals = false, block = null }) => {
	const { startOfCalendar, startOfMonth, setSelectedDay } = useBookings();


	const { data: jobCategories } = useQuery(jobCategoriesQuery());

	const jobCategoriesKeyed = useMemo(() => jobCategories?.reduce((acc, job) => {
		acc[job.key] = job;
		return acc;
	}, {}), [jobCategories]);

	const isOutsideMonth = useMemo(() => date
		? date.monthLong !== startOfMonth.monthLong
		: null, [date, startOfMonth]);

	const showMonth = useMemo(() => date
		? date.equals(startOfCalendar) || date.equals(startOfMonth) || (date.monthLong !== startOfMonth.monthLong && date.day === 1)
		: false, [date, startOfCalendar, startOfMonth]);

	const hasSlots = useMemo(() => Object.entries(data).some(([key, value]) => value.slots > 0) && !block, [data]);

	return (
		<Box
			padding={1}
			flex="1"
			borderLeft={theme => `1px solid ${theme.palette.divider}`}
			bgcolor={totals ? "primary.light" : (isOutsideMonth ? "grey.200" : null)}
			sx={totals ? {} : {
				cursor: 'pointer',
				'&:hover': { backgroundColor: (isOutsideMonth ? "grey.300" : "grey.100") },
				position: 'relative',
				maxWidth: 280,
				minWidth: 180
			}}
			onClick={() => setSelectedDay({
				date: date,
				facilty: facility
			})}
		>
			<Box fontSize="75%">
				<span>{totals ? "Totals" : date.toFormat('EEE, ')}</span>
				{!totals && (
					<span style={{ fontWeight: showMonth ? 'bold' : 'normal' }}>
						{showMonth ? date.toFormat('MMM dd') : date.toFormat('dd')}
					</span>
				)}
			</Box>

			{hasSlots && (
				<Stack direction="column" py={2} px={0.5} mx="auto" gap={0.5}>
					{Object.entries(data).map(([key, value], i) => {
						const hasCapacity = value.slots > 0;
						const overCapacity = value.bookings > value.slots;
						const atCapacity = value.slots > 0 && value.bookings >= value.slots;
						return (
							<Stack key={i} direction="row" gap={1} alignItems="center">
								<Box width={10} height={10} bgcolor={!hasCapacity ? "grey.400" : ((hasCapacity && atCapacity) ? "error.light" : "success.light")} borderRadius="50%" />
								<Box color={overCapacity ? "error.main" : null} fontSize="75%" whiteSpace="nowrap">
									{jobCategoriesKeyed[key].name}
								</Box>
								<Box position="relative" flex="1">
									<Box
										position="absolute" top="50%" left={0} width="100%" height="100%"
										borderTop={theme => `1px dotted ${overCapacity ? theme.palette.error.light : theme.palette.grey[500]}`}
										sx={{ transform: 'translateY(-50%)' }}
									/>
								</Box>
								<Stack direction="row" fontSize="90%" gap={1}>
									<Box width={20} textAlign="center" fontWeight="bold" color={overCapacity ? "error.main" : null}>{value.bookings}</Box>
									<Box color="grey.500" fontWeight="light">|</Box>
									<Box width={20} textAlign="center" fontWeight="light">{value.slots}</Box>
								</Stack>
							</Stack>
						);
					})}
				</Stack>
			)}

			{(!hasSlots && !block) && (
				<Box sx={{
					py: 4,
					px: 0.5,
					display: 'flex',
					justifyContent: 'center',
					minWidth: 100
				}}>
					<Stack alignItems={'center'} >
						<Typography variant="subtitle2" sx={{ whiteSpace: 'nowrap' }}>
							No slots
						</Typography>
					</Stack>
				</Box>
			)}

			{block && (
				<Box sx={{
					py: 2,
					px: 0.5,
					display: 'flex',
					justifyContent: 'center',
				}}>
					<Stack alignItems={'center'} >
						<Typography color="textSecondary">
							Blocked
						</Typography>
						<Typography variant="subtitle2">
							{block.reason}
						</Typography>
					</Stack>
				</Box>
			)}
		</Box>
	)
}

export default BookingsPage