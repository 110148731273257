import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { useTheme } from '@mui/material/styles';
import { Collapse, List, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import MenuItem from './MenuItem';

import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useGate } from '~/hooks/useGate';


const NavCollapse = ({ menu, level }) => {
	const theme = useTheme();

	const [open, setOpen] = useState(false);
	const [selected, setSelected] = useState(null);
	const { pathname } = useLocation();

	const { gate } = useGate();

	const handleClick = () => {
		setOpen(!open);
		setSelected(!selected ? menu.id : null);
	};

	const checkOpenForParent = (child, id) => {
		child.forEach((item) => {
			if (item.url === pathname) {
				setOpen(true);
				setSelected(id);
			}
		});
	};

	// menu collapse for sub-levels
	useEffect(() => {
		setOpen(false);
		setSelected(null);
		if (menu.children) {
			menu.children.forEach((item) => {
				if (item.children?.length) {
					checkOpenForParent(item.children, menu.id);
				}
				if (item.url === pathname) {
					setSelected(menu.id);
					setOpen(true);
				}
			});
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pathname, menu.children]);

	// menu collapse & item
	const menus = menu.children?.filter(menu => gate(menu.gate ?? true)).map((item, i) => {
		switch (item.type) {
			case 'collapse':
				return <NavCollapse key={i} menu={item} level={level + 1} />;
			case 'item':
				return <MenuItem key={i} item={item} level={level + 1} />;
			default:
				return (
					<Typography key={item.id} variant="h6" color="error" align="center">
						Menu Items Error
					</Typography>
				);
		}
	});

	const Icon = menu.icon;
	const menuIcon = menu.icon ? (
		<Icon size="1.3rem" style={{ marginTop: 'auto', marginBottom: 'auto' }} />
	) : (
		<FiberManualRecordIcon
			sx={{
				width: selected === menu.id ? 8 : 6,
				height: selected === menu.id ? 8 : 6
			}}
			fontSize={level > 0 ? 'inherit' : 'medium'}
		/>
	);

	// if no children under the collapse heading, dont render
	if (!menus.length) {
		return null;
	}

	return (
		<>
			<ListItemButton
				sx={{
					mb: 0.5,
					alignItems: 'flex-start',
					backgroundColor: level > 1 ? 'transparent !important' : 'inherit',
					py: level > 1 ? 1 : 1.25,
					pl: `${level * 24}px`
				}}
				selected={selected === menu.id}
				onClick={handleClick}
			>
				<ListItemIcon sx={{ my: 'auto', minWidth: !menu.icon ? 18 : 36 }}>{menuIcon}</ListItemIcon>
				<ListItemText
					primary={
						<Typography variant={'body1'} color="inherit" sx={{ my: 'auto', fontWeight: selected === menu.id ? '600' : '400' }}>
							{menu.title}
						</Typography>
					}
					secondary={
						menu.caption && (
							<Typography variant="caption" sx={{ ...theme.typography.subMenuCaption }} display="block" gutterBottom>
								{menu.caption}
							</Typography>
						)
					}
				/>
				{open ? (
					<ExpandLessIcon fontSize="1rem" style={{ marginTop: 'auto', marginBottom: 'auto' }} />
				) : (
					<ExpandMoreIcon fontSize="1rem" style={{ marginTop: 'auto', marginBottom: 'auto' }} />
				)}
			</ListItemButton>
			<Collapse in={open} timeout="auto" unmountOnExit>
				<List
					component="div"
					disablePadding
					sx={{
						position: 'relative',
						'&:after': {
							content: "''",
							position: 'absolute',
							left: '32px',
							top: 0,
							height: '100%',
							width: '1px',
							opacity: 1,
							background: theme.palette.primary.light
						}
					}}
				>
					{menus}
				</List>
			</Collapse>
		</>
	);
};

NavCollapse.propTypes = {
	menu: PropTypes.object,
	level: PropTypes.number
};

export default NavCollapse;
