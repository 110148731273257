import React, { useCallback } from "react";
import { usePopupState } from "material-ui-popup-state/hooks";
import EstimateRepairBookingDialog from "~/components/Dialogs/EstimateJobBookingDialog";

// context setup
const EstimateJobBookingDialogContext = React.createContext({
	open: () => { },
	close: () => { },
});
export const __EstimateJobBookingDialogContext = EstimateJobBookingDialogContext;

export const EstimateJobBookingDialogProvider = ({ children }) => {

	const popupState = usePopupState({
		variant: 'dialog',
		popupId: 'EstimateJobBookingDialog',
	});

	const open = useCallback(() => {
		popupState.open();
	}, [popupState]);

	const close = useCallback(() => {
		popupState.close();
	}, [popupState]);

	return (
		<EstimateJobBookingDialogContext.Provider
			value={{ open, close }}
		>
			<>
				<EstimateRepairBookingDialog
					isOpen={popupState.isOpen}
					onClose={close}
				/>
				{children}
			</>
		</EstimateJobBookingDialogContext.Provider>
	);
};
