import React, { Fragment, useCallback, useMemo, useState } from "react";
import { Alert, Box, Button, Card, CardContent, Checkbox, FormControl, Grid, InputLabel, List, ListItem, ListItemButton, ListItemIcon, ListItemText, MenuItem, Select, Skeleton, Stack, Table, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers-pro";
import { useForm } from "~/hooks/useForm";
import FacilityDropdown from "../Dropdowns/FacilityDropdown";
import { jobCategoriesQuery } from "~/loaders/jobCategoriesLoader";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { createBooking, getBookingCalendarCheckBooking } from "~/requests/bookings";
import { useCrud } from "~/hooks/useCrud";
import { LoadingButton } from "@mui/lab";
import { DateTime } from "luxon";
import { bookingCalendarNextAvailableQuery } from "~/loaders/bookingsLoader";
import { enqueueSnackbar } from "notistack";

const JobBookingForm = ({ onCancel = () => { }, showCancelButton = true }) => {
	const { submission } = useForm();
	const queryClient = useQueryClient();

	const [facilityId, setFacilityId] = useState(submission.assessment?.facility_uuid);
	const [fromDate, setFromDate] = useState(DateTime.now().startOf("day").plus({ weeks: 2 }));
	const [selectedDate, setSelectedDate] = useState(null);
	const [isSaving, setIsSaving] = useState(false);
	const [requestMode, setRequestMode] = useState(false);
	const [notSuitableReason, setNotSuitableReason] = useState(null);

	const crud = useCrud({ createRequest: createBooking, queryKey: "bookings" });

	const { data: jobCategories } = useQuery(jobCategoriesQuery());
	const jobCategory = useMemo(() => jobCategories?.find((category) => category.key === submission?.meta?.job_category), [jobCategories, submission]);

	const { data: nextAvailable = [], isLoading: nextAvailableLoading, refetch: refetchNextAvailable } = useQuery(
		bookingCalendarNextAvailableQuery(facilityId, jobCategory?.id, fromDate?.toISO())
	);

	const nextAvailableGroupedByWeek = useMemo(() => {
		return nextAvailable.reduce((acc, iso) => {
			const dt = DateTime.fromISO(iso);
			const label = `Week ${dt.weekNumber} (${dt.startOf("week").toFormat("dd LLL")} - ${dt.endOf("week").toFormat("dd LLL")})`;
			(acc[label] = acc[label] || []).push(iso);
			return acc;
		}, {});
	}, [nextAvailable]);

	const resetState = useCallback(() => {
		setFromDate(DateTime.now().startOf("day").plus({ weeks: 2 }));
		setSelectedDate(null);
		setFacilityId(submission.assessment?.facility_uuid);
		setRequestMode(false);
		setNotSuitableReason(null);
	}, [submission]);

	const handleCancel = useCallback(() => {
		resetState();
		onCancel();
	}, [onCancel, resetState]);

	const saveBooking = useCallback(() => {
		const data = {
			submission_id: submission.id,
			facility_uuid: facilityId,
			job_category_id: jobCategory.id,
			booking_datetime: requestMode ? null : selectedDate,
			unsuitable_reason: requestMode ? notSuitableReason : null,
		};

		setIsSaving(true);
		getBookingCalendarCheckBooking(facilityId, data)
			.then((response) => {

				if (response.data?.data?.result) {
					crud.doSave({
						id: null,
						values: data,
						toastLabel: "Booking",
						onSettled: () => setIsSaving(false),
						onSuccess: () => {
							onCancel();
							queryClient.invalidateQueries({
								queryKey: ["form", "estimate", "submission", submission.uuid],
							});
						},
						onError: (error) => console.error(error),
					});
				} else {
					enqueueSnackbar("Booking date is not available anymore", { variant: "error" });
					setIsSaving(false);
					setSelectedDate(null);
					refetchNextAvailable();
				}
			})
			.catch((error) => {
				enqueueSnackbar(error.response?.data?.message || "Unable to save booking", { variant: "error" });
				setIsSaving(false);
				console.error(error);
			});
	}, [submission, facilityId, jobCategory, selectedDate, notSuitableReason, requestMode, crud, onCancel, queryClient]);

	return (
		<>
			<Grid container mt={1} spacing={2}>
				<Grid item xs={12}>
					<Card variant="outlined">
						<CardContent>
							<Table>
								<tr>
									<td>Repair Type</td>
									{jobCategory && (
										<td style={{ fontWeight: "bold" }}>{jobCategory?.name}</td>
									)}
									{!jobCategory && (
										<td style={{ fontWeight: "bold" }}><Typography color='warning.main'>! MISSING</Typography></td>
									)}
								</tr>
								<tr>
									<td>Rego</td>
									<td style={{ fontWeight: "bold" }}>{submission.summary?.rego}</td>
								</tr>
								<tr>
									<td>Customer</td>
									<td style={{ fontWeight: "bold" }}>{submission.summary?.customer}</td>
								</tr>
								<tr>
									<td>Vehicle</td>
									<td style={{ fontWeight: "bold" }}>{submission.summary?.vehicle}</td>
								</tr>
								<tr>
									<td>Mobile</td>
									<td style={{ fontWeight: "bold" }}>{submission.summary?.customer_mobile}</td>
								</tr>
								<tr>
									<td>Email</td>
									<td style={{ fontWeight: "bold" }}>{submission.summary?.customer_email}</td>
								</tr>
							</Table>
						</CardContent>
					</Card>
				</Grid>

				<Grid item xs={12}>
					<Stack spacing={3}>
						<FacilityDropdown
							value={facilityId}
							fullWidth
							onChange={(name, value) => setFacilityId(value)}
							required
						/>

						{requestMode && (
							<>
								<Alert severity="info">Please select a reason why the customer cannot book on the available dates</Alert>
								<FormControl fullWidth>
									<InputLabel id="reason-label">Reason</InputLabel>
									<Select
										id="reason-label"
										value={notSuitableReason}
										onChange={(e) => setNotSuitableReason(e.target.value)}
										placeholder="Select a reason"
										label="Reason"
									>
										<MenuItem value="total_loss">Potential Total Loss / Assessor Review</MenuItem>
										<MenuItem value="change_of_repairer">Change of Repairer</MenuItem>
										<MenuItem value="other">Other - Office to contact customer</MenuItem>
									</Select>
								</FormControl>
								<Button onClick={() => setRequestMode(false)} color="secondary">Return to booking</Button>
							</>
						)}

						{!requestMode && (
							<>
								<DatePicker
									label="Find From Booking Date"
									value={fromDate}
									format="dd MMM yyyy"
									onChange={setFromDate}
									disablePast
									minDate={DateTime.now().startOf("day").plus({ weeks: 2 })}
									slotProps={{
										field: { clearable: true },
									}}
								/>

								{facilityId && (
									<>
										<Typography variant="h4">Next Available Dates</Typography>
										{!fromDate && (
											<Typography variant="body2" textAlign="center">
												Select a date to view available dates
											</Typography>
										)}
										{fromDate && (
											<Card variant="outlined">
												{(!nextAvailable.length && !nextAvailableLoading) && (
													<Typography variant="body2" textAlign="center" sx={{ my: 2 }}>
														No available dates
													</Typography>
												)}

												{nextAvailableLoading && (
													<Box p={2}>
														<Skeleton height={40} />
														<Skeleton height={40} />
														<Skeleton height={40} />
													</Box>
												)}

												{!!nextAvailable.length && (
													<List dense>
														{Object.entries(nextAvailableGroupedByWeek).map(([weekLabel, dates]) => (
															<Fragment key={weekLabel}>
																<ListItem>
																	<Typography variant="subtitle2" sx={{ pl: 1 }}>
																		{weekLabel}
																	</Typography>
																</ListItem>
																{dates.map((date) => (
																	<ListItem key={date}>
																		<ListItemButton role={undefined} onClick={() => setSelectedDate(date)}>
																			<ListItemIcon>
																				<Checkbox
																					edge="start"
																					checked={selectedDate === date}
																					tabIndex={-1}
																					disableRipple
																					inputProps={{ "aria-labelledby": `${date}-radio` }}
																				/>
																			</ListItemIcon>
																			<ListItemText id={`${date}-radio`} primary={DateTime.fromISO(date).toFormat("ccc, dd LLLL yyyy")} />
																		</ListItemButton>
																	</ListItem>
																))}
															</Fragment>
														))}
													</List>
												)}
											</Card>
										)}
										<Button onClick={() => setRequestMode(true)} color="secondary">No suitable booking date?</Button>
									</>
								)}
							</>
						)}
					</Stack>
				</Grid>
			</Grid>

			<Box sx={{ display: "flex", alignItems: "center", mt: 4 }}>
				{showCancelButton && (
					<Button color="secondary" onClick={handleCancel} sx={{ mr: "auto" }}>
						Cancel
					</Button>
				)}
				{!requestMode && (
					<LoadingButton loading={isSaving} variant="contained" disabled={!selectedDate} onClick={saveBooking} fullWidth={!showCancelButton} size="large">
						Create Booking
					</LoadingButton>
				)}
				{requestMode && (
					<LoadingButton loading={isSaving} variant="contained" disabled={!notSuitableReason} onClick={saveBooking} fullWidth={!showCancelButton} size="large">
						Finish Booking Attempt
					</LoadingButton>
				)}
			</Box>
		</>
	);
};

export default JobBookingForm;
