import apiHandler from "~/apiHandler";

export const getJobCategories = (params = {}, signal = undefined) => apiHandler({
	method: "get",
	url: "job-categories",
	dataOrParams: params,
	signal
});

export const storeJobCategories = (data, signal = undefined) => apiHandler({
	method: "post",
	url: "job-categories",
	dataOrParams: data,
	signal
});