import React from "react";
import EstimateFormPage from "../../components/Forms/EstimateFormPage";
import PageTitle from "../../components/PageTitle";
import { Alert, Stack } from "@mui/material";
import { toDate } from "~/helpers/stringHelpers";
import { useEstimateJobBookingDialog } from "~/hooks/dialogs/useEstimateJobBookingDialog";
import { useForm } from "~/hooks/useForm";
import JobBookingForm from "~/components/JobBooking/JobBookingForm";

const NotBooked = () => {
	const { open } = useEstimateJobBookingDialog();

	return (
		<Stack spacing={2}>
			<Alert severity="warning">
				Repair not yet booked.
			</Alert>
		</Stack>
	);
};

const Booked = () => {
	const { submission } = useForm();

	return (
		<>

			{submission.repair_booking.booking_datetime && (
				<Alert severity="success">
					Repair booked for <strong>{toDate(submission.repair_booking.booking_datetime, 'EEEE, d MMMM yyyy')}</strong>
				</Alert>
			)}

			{submission.repair_booking.unsuitable_reason && (
				<Alert severity="warning">
					Repair booking was attempted but the customer is unable to book.
				</Alert>
			)}
		</>
	);
};

const PageBookRepairs = () => {
	const { submission } = useForm();

	return (
		<EstimateFormPage>
			<PageTitle>Book Repairs</PageTitle>

			{!submission.repair_booking && (
				<>
					<NotBooked />
					<JobBookingForm showCancelButton={false} />
				</>
			)}

			{submission.repair_booking && (
				<Booked />
			)}

			{/* Pass showCancelButton as false for non-dialog form */}
		</EstimateFormPage>
	);
};

export default React.memo(PageBookRepairs);
