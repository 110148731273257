import React from "react";
import { Box, Typography } from "@mui/material";

const RegoPlate = ({ text, small = false }) => {
	return (
		<Box
			sx={{
				display: "inline-flex",
				justifyContent: "center",
				alignItems: "center",
				backgroundColor: "#000",
				color: "#FFF",
				borderRadius: small ? "6px" : "8px",
				paddingX: small ? "8px" : "12px",
				paddingY: small ? "2px" : "4px",
				fontSize: small ? "12px" : "14px",
				textTransform: "uppercase",
			}}
		>
			<Typography component="span">{text}</Typography>
		</Box>
	);
};


export default RegoPlate;
