import { useQuery } from "@tanstack/react-query";
import { DateTime } from "luxon";
import React, { useMemo, useState } from "react";
import { bookingFacilityCapacitiesQuery } from "~/loaders/bookingsLoader";

// context setup
const BookingSlotsDialogContext = React.createContext({
	selectedFacility: null,
	setSelectedFacility: null,
	dateRange: [null, null],
	setDateRange: null,
	selectedDays: new Set(),
	setSelectedDays: null,
	modifiedDays: {},
	setModifiedDays: null,
	capacitiesLoading: false,
	capacities: {},
	calendarData: {}
});

export const __BookingSlotsDialogContext = BookingSlotsDialogContext;

export const BookingSlotsDialogProvider = ({ children }) => {
	const [selectedFacility, setSelectedFacility] = useState(null);

	const [dateRange, setDateRange] = useState(() => {
		const now = DateTime.now();
		const monthStart = now.startOf('month');
		const monthEnd = now.endOf('month');
		return [
			monthStart.minus({ days: monthStart.weekday - 1 }),
			monthEnd.plus({ days: 7 - monthEnd.weekday })
		];
	});

	const [selectedDays, setSelectedDays] = useState(() => new Set());
	const [modifiedDays, setModifiedDays] = useState({});

	const fromDate = useMemo(() => dateRange[0], [dateRange]);
	const toDate = useMemo(() => dateRange[1], [dateRange]);

	const { data: capacitiesData = {}, isLoading: capacitiesLoading } = useQuery(bookingFacilityCapacitiesQuery(selectedFacility?.uuid, fromDate.toISO(), toDate.toISO()));

	// parse capacities data into desired format
	const capacities = useMemo(() => {
		return Object.entries(capacitiesData).reduce((updatedDays, [date, data]) => {
			// convert date to be in user timezone
			const dateObj = DateTime.fromISO(date, { zone: 'utc' }).setZone('local');

			const slots = data.capacity ? data.capacity.map(slot => ({
				job_category_id: slot.job_category_id,
				slots: slot.slots,
				key: slot.job_category.key,
				name: slot.job_category.name
			})) : [];

			updatedDays[dateObj.toISO()] = {
				slots,
				block: data.block?.[0]?.reason || null
			};

			return updatedDays;
		}, {});
	}, [capacitiesData]);

	const calendarData = useMemo(() => ({ ...capacities, ...modifiedDays }), [modifiedDays, capacities]);

	// useEffect(() => {
	// 	if (Object.keys(capacities).length === 0) return;
	// 	setModifiedDays(prev => ({ ...prev, ...capacities }));
	// }, [capacities]);

	return (
		<BookingSlotsDialogContext.Provider
			value={{
				selectedFacility,
				setSelectedFacility,
				dateRange,
				setDateRange,
				selectedDays,
				setSelectedDays,
				modifiedDays,
				setModifiedDays,
				capacitiesLoading,
				capacities,
				calendarData
			}}
		>
			{children}
		</BookingSlotsDialogContext.Provider>
	);
};