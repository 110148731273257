import { Box, Button, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { useQueryErrorResetBoundary } from '@tanstack/react-query';
import { forwardRef, useEffect, useLayoutEffect, useRef } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Outlet, useLocation } from 'react-router-dom';
import AssessmentEditDialog from '~/components/Dialogs/AssessmentEditDialog';
import GlobalDialogsProvider from '~/components/GlobalDialogs';
import useGlobalState from '~/hooks/useGlobalState';
import Sidebar from '~/layout/Sidebar';
import { useLayoutProvider } from "~/providers/LayoutProvider";
import Header from './Header';

// styles
const StyledMain = styled('main', {
	shouldForwardProp: (prop) => prop !== 'open' && prop !== 'theme'
})(({ theme, open }) => ({
	flexGrow: 1,
	paddingLeft: theme.spacing(3),
	paddingRight: theme.spacing(3),
	paddingTop: theme.spacing(2),
	paddingBottom: theme.spacing(4),
	marginTop: `${theme.headerHeight}px`,
	transition: theme.transitions.create(
		'margin',
		open
			? {
				easing: theme.transitions.easing.easeOut,
				duration: theme.transitions.duration.enteringScreen
			}
			: {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen
			}
	),
	[theme.breakpoints.up('md')]: {
		marginLeft: open ? 0 : -(theme.sidebarMenuWidth - 20),
		width: `calc(100% - ${theme.sidebarMenuWidth}px)`,
	},
	[theme.breakpoints.down('md')]: {
		width: `calc(100% - ${theme.sidebarMenuWidth}px)`,
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
		paddingTop: theme.spacing(1),
	},
	[theme.breakpoints.down('sm')]: {
		width: `calc(100% - ${theme.sidebarMenuWidth}px)`,
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
		paddingTop: theme.spacing(0),
	}
}));

const Main = forwardRef((props, ref) => {
	return <StyledMain ref={ref} {...props} />;
});

Main.displayName = 'Main';


// make Main but as a normal compoent
// const Main = forwardRef(({ theme, open, children }, ref) => {
// 	return (
// 		<Box component="main" ref={ref} sx={{
// 			flexGrow: 1,
// 			paddingLeft: theme.spacing(3),
// 			paddingRight: theme.spacing(3),
// 			paddingTop: theme.spacing(2),
// 			paddingBottom: theme.spacing(4),
// 			marginTop: `${theme.headerHeight}px`,
// 			transition: theme.transitions.create(
// 				'margin',
// 				open
// 					? {
// 						easing: theme.transitions.easing.easeOut,
// 						duration: theme.transitions.duration.enteringScreen
// 					}
// 					: {
// 						easing: theme.transitions.easing.sharp,
// 						duration: theme.transitions.duration.leavingScreen
// 					}
// 			),
// 			[theme.breakpoints.up('md')]: {
// 				marginLeft: open ? 0 : -(theme.sidebarMenuWidth - 20),
// 				width: `calc(100% - ${theme.sidebarMenuWidth}px)`,
// 			},
// 			[theme.breakpoints.down('md')]: {
// 				width: `calc(100% - ${theme.sidebarMenuWidth}px)`,
// 				paddingLeft: theme.spacing(2),
// 				paddingRight: theme.spacing(2),
// 				paddingTop: theme.spacing(1),
// 			},
// 			[theme.breakpoints.down('sm')]: {
// 				width: `calc(100% - ${theme.sidebarMenuWidth}px)`,
// 				paddingLeft: theme.spacing(1),
// 				paddingRight: theme.spacing(1),
// 				paddingTop: theme.spacing(0),
// 			}
// 		}}>
// 			{children}
// 		</Box>
// 	);
// });



const MainLayout = () => {
	const theme = useTheme();
	const { sidemenuOpen, toggleSidemenu, closeSidemenu } = useLayoutProvider();

	const { pathname } = useLocation();
	const contentRef = useRef();
	const { setGlobalState, globalKeys } = useGlobalState();

	const { reset } = useQueryErrorResetBoundary();

	useLayoutEffect(() => {
		// on navigation, scroll to top of page
		if (contentRef?.current) {
			contentRef?.current.scrollTo({
				top: 0,
				left: 0,
				behavior: 'smooth'
			});
		}
	}, [pathname]);

	useEffect(() => {
		setGlobalState(globalKeys.lastMainUrl, pathname);
	}, [globalKeys.lastMainUrl, pathname, setGlobalState]);

	return (
		<ErrorBoundary
			onReset={reset}
			fallbackRender={({ resetErrorBoundary }) => (
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						alignItems: 'center',
						height: '100vh',
						backgroundColor: theme.palette.background.default,
						color: theme.palette.text.primary,
						p: 3,
					}}
				>
					<Box sx={{ mb: 2, textAlign: 'center' }}>
						<Typography variant="h4" gutterBottom>
							Something went wrong.
						</Typography>
						<Typography variant="body1">
							We encountered an unexpected error. Please try again.
						</Typography>
					</Box>
					<Button variant="contained" color="primary" onClick={() => resetErrorBoundary()}>
						Retry
					</Button>
				</Box>
			)}
		>
			<GlobalDialogsProvider>
				<Box sx={{ display: 'flex' }}>

					<Header />

					<Sidebar drawerOpen={sidemenuOpen} drawerToggle={toggleSidemenu} onClose={closeSidemenu} />
					<Main theme={theme} open={sidemenuOpen} ref={contentRef}>
						<Outlet />
					</Main>

					<AssessmentEditDialog />

				</Box>
			</GlobalDialogsProvider>
		</ErrorBoundary>
	);
};

export default MainLayout;
