import { FormControl, FormHelperText, TextField } from '@mui/material';
import { DateRangePicker } from '@mui/x-date-pickers-pro';
import { useField, useFormikContext } from 'formik';

const FormikDateRangePicker = ({ name, slotProps = {}, ...props }) => {
	const { setFieldValue } = useFormikContext(); // Access Formik's context to set the field value
	const [field, meta] = useField(name); // Hook into Formik's field and meta state for the given name

	const error = meta.touched && meta.error;

	const handleChange = (newValue) => {
		if (!newValue || !Array.isArray(newValue)) {
			setFieldValue(name, [null, null]);
			return;
		}

		const [start, end] = newValue;

		const adjustedStart = start
			? start.startOf('day')
			: null;

		const adjustedEnd = end
			? end.endOf('day')
			: null;

		setFieldValue(name, [adjustedStart, adjustedEnd]);
	};

	return (
		<FormControl fullWidth error={!!error}>
			<DateRangePicker
				value={field.value || [null, null]}
				onChange={handleChange}
				format="dd/MM/yyyy"

				slots={{
					textField: TextField,
				}}
				slotProps={{
					textField: () => ({
						fullWidth: true,
						error: !!error,
					}),
					...slotProps
				}}
				{...props}
			/>
			{error && <FormHelperText>{meta.error}</FormHelperText>}
		</FormControl>
	);
};

export default FormikDateRangePicker;
