import { useQuery } from "@tanstack/react-query";
import { usePopupState } from "material-ui-popup-state/hooks";
import React, { useCallback, useState } from "react";
import JobBookingDialog from "~/components/Dialogs/JobBookingDialog";
import { submissionQuery } from "~/loaders/estimateFormLoader";

// context setup
const JobBookingDialogContext = React.createContext({
	close: () => { },
	open: (id) => { },
	estimate: null,
	booking: null,
	facilityId: null,
	setFacilityId: (x) => { },
	targetDate: null,
	setTargetDate: (x) => { },
});
export const __JobBookingDialogContext = JobBookingDialogContext;

export const JobBookingDialogProvider = ({ children }) => {

	const popupState = usePopupState({
		variant: 'dialog',
		popupId: 'JobBookingDialog',
	});


	const [estimateId, setEstimateId] = useState(null);
	const [facilityId, setFacilityId] = useState(null);
	const [targetDate, setTargetDate] = useState(null);
	const [booking, setBooking] = useState(null);

	const { data: estimate } = useQuery(submissionQuery(estimateId));

	const open = useCallback((params = {}) => {
		const { estimateId = null, facilityId = null, date = null, booking = null } = params;

		if (estimateId) {
			setEstimateId(estimateId);
		}
		if (facilityId) {
			setFacilityId(facilityId);
		}
		if (date) {
			setTargetDate(date);
		}
		// if booking, the estimate will come from it
		if (booking) {
			setBooking(booking);
			setEstimateId(booking.submission.uuid);
		}

		popupState.open();
	}, [popupState]);

	const close = useCallback(() => {
		setEstimateId(null);
		setBooking(null);
		setFacilityId(null);
		setTargetDate(null);
		popupState.close();
	}, [popupState]);

	return (
		<JobBookingDialogContext.Provider value={{ close, open, estimate, booking, facilityId, setFacilityId, targetDate, setTargetDate }}>
			<>
				<JobBookingDialog
					isOpen={popupState.isOpen}
					onClose={close}
				/>
				{children}
			</>
		</JobBookingDialogContext.Provider>
	);
};

JobBookingDialogProvider.propTypes = {
}
