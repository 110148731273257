import apiHandler from "~/apiHandler";

export const updateBookingCapacity = (facility_uuid, data = {}, signal = null) => apiHandler({
	method: "post",
	url: `bookings/${facility_uuid}/capacity`,
	dataOrParams: data,
	signal
});

// for calander
export const getBookingCalendarAvailability = (facility_uuid, params = {}, signal = null) => apiHandler({
	method: "get",
	url: `bookings/${facility_uuid}/calendar-availability`,
	dataOrParams: params,
	signal
});

export const getBookingCalendarCapacity = (facility_uuid, params = {}, signal = null) => apiHandler({
	method: "get",
	url: `bookings/${facility_uuid}/calendar-capacity`,
	dataOrParams: params,
	signal
});

export const getBookingCalendarNextAvailable = (facility_uuid, params = {}, signal = null) => apiHandler({
	method: "get",
	url: `bookings/${facility_uuid}/next-available`,
	dataOrParams: params,
	signal
});

// not specific to a booking record
export const getBookingCalendarCanBook = (facility_uuid, params = {}, signal = null) => apiHandler({
	method: "get",
	url: `bookings/${facility_uuid}/can-book`,
	dataOrParams: params,
	signal
});

// checks if specific booking record is allow 
export const getBookingCalendarCheckBooking = (facility_uuid, params = {}, signal = null) => apiHandler({
	method: "get",
	url: `bookings/${facility_uuid}/check`,
	dataOrParams: params,
	signal
});

export const getBookingCapacitiesByFacility = (facility_uuid, params = {}, signal = null) => apiHandler({
	method: "get",
	url: `bookings/${facility_uuid}/capacity`,
	dataOrParams: params,
	signal
});

export const getBookingsCapacities = (startDate, endDate, facility_uuids, signal = null) => apiHandler({
	method: "get",
	url: `bookings/capacity`,
	dataOrParams: {
		start_date: startDate,
		end_date: endDate,
		facility_uuids
	},
	signal
});

export const getBookings = (startDate, endDate, facility_uuids, params = {}, signal = null) => apiHandler({
	method: "get",
	url: `bookings`,
	dataOrParams: {
		start_date: startDate,
		end_date: endDate,
		facility_uuids,
		...params
	},
	signal
});

export const createBooking = (params = {}, signal = null) => apiHandler({
	method: "post",
	url: `bookings`,
	dataOrParams: params,
	signal
});

export const updateBooking = (bookingId, data = {}, signal = null) => apiHandler({
	method: "put",
	url: `bookings/${bookingId}`,
	dataOrParams: data,
	signal
});