import apiHandler from "~/apiHandler";

/**
 * Searches for submissions based on a search term.
 *
 * @param {string} term - The search term to use.
 * @param {AbortSignal|null} [signal=null] - The optional AbortSignal for canceling the request.
 * @returns {Promise<any>} A Promise that resolves with the search results.
 *
 * @example
 * const results = await searchSubmissions("searchTerm", controller.signal);
 */
export const searchSubmissions = (term, params = {}, signal = undefined) => apiHandler({
	method: "get",
	url: "submissions/search",
	dataOrParams: {
		q: term,
		...params
	},
	signal
});

/**
 * Retrieves a list of submissions.
 *
 * @param {object} [params={}] - Optional parameters for the request.
 * @param {AbortSignal|null} [signal=null] - The optional AbortSignal for canceling the request.
 * @returns {Promise<any>} A Promise that resolves with the list of submissions.
 *
 * @example
 * const submissionsList = await getSubmissions({ page: 1, limit: 10 }, controller.signal);
 */
export const getSubmissions = (params = {}, signal = null) => apiHandler({
	method: "get",
	url: "submissions",
	dataOrParams: params,
	signal
});

/**
 * Retrieves information about a specific submission.
 *
 * @param {string} uuid - The UUID of the submission to retrieve.
 * @param {object} [params={}] - Optional parameters for the request.
 * @param {AbortSignal|null} [signal=null] - The optional AbortSignal for canceling the request.
 * @returns {Promise<any>} A Promise that resolves with the submission information.
 *
 * @example
 * const submissionInfo = await getSubmission("submissionUUID", { includeDetails: true }, controller.signal);
 */
export const getSubmission = (uuid, params = {}, signal = null) => apiHandler({
	method: "get",
	url: `submissions/${uuid}`,
	dataOrParams: params,
	signal
});

/**
 * Retrieves parent of a specific submission.
 *
 * @param {string} uuid - The UUID of the submission to retrieve.
 * @param {object} [params={}] - Optional parameters for the request.
 * @param {AbortSignal|null} [signal=null] - The optional AbortSignal for canceling the request.
 * @returns {Promise<any>} A Promise that resolves with the submission information.
 *
 * @example
 * const submissionInfo = await getSubmission("submissionUUID", { }, controller.signal);
 */
export const getParentSubmission = (uuid, params = {}, signal = null) => apiHandler({
	method: "get",
	url: `submissions/${uuid}/parent`,
	dataOrParams: params,
	signal
});

/**
 * Retrieves miscellaneous prices for a form and UUID.
 *
 * @param {string} form - The form identifier.
 * @param {string} uuid - The UUID of the submission.
 * @param {object} [params={}] - Optional parameters for the request.
 * @param {AbortSignal|null} [signal=null] - The optional AbortSignal for canceling the request.
 * @returns {Promise<any>} A Promise that resolves with the miscellaneous prices.
 *
 * @example
 * const miscPrices = await getMiscPrices("formID", "submissionUUID", { type: "type1" }, controller.signal);
 */
export const getMiscPrices = (form, uuid, params = {}, signal = null) => apiHandler({
	method: "get",
	url: `/forms/${form}/${uuid}/misc-prices`,
	dataOrParams: params,
	signal
});

/**
 * Retrieves panel prices for a form and UUID.
 *
 * @param {string} form - The form identifier.
 * @param {string} uuid - The UUID of the submission.
 * @param {object} [params={}] - Optional parameters for the request.
 * @param {AbortSignal|null} [signal=null] - The optional AbortSignal for canceling the request.
 * @returns {Promise<any>} A Promise that resolves with the panel prices.
 *
 * @example
 * const panelPrices = await getPanelPrices("formID", "submissionUUID", { type: "type1" }, controller.signal);
 */
export const getPanelPrices = (form, uuid, params = {}, signal = null) => apiHandler({
	method: "get",
	url: `/forms/${form}/${uuid}/panel-prices`,
	dataOrParams: params,
	signal
});


export const getPanelHours = (form, uuid, params = {}, signal = null) => apiHandler({
	method: "get",
	url: `/forms/${form}/${uuid}/panel-hours`,
	dataOrParams: params,
	signal
});

/**
 * Updates an existing submission.
 *
 * @param {string} uuid - The UUID of the submission to update.
 * @param {object} [data={}] - The updated data for the submission.
 * @param {AbortSignal|null} [signal=null] - The optional AbortSignal for canceling the request.
 * @returns {Promise<any>} A Promise that resolves with the updated submission data.
 *
 * @example
 * const updatedSubmission = await updateSubmission("submissionUUID", { key: "value" }, controller.signal);
 */
export const updateSubmission = (uuid, data = {}, signal = null) => apiHandler({
	method: "put",
	url: `submissions/${uuid}`,
	dataOrParams: data,
	signal
});

/**
 * Retrieves values for a specific submission.
 *
 * @param {string} uuid - The UUID of the submission to retrieve values for.
 * @param {object} [params={}] - Optional parameters for the request.
 * @param {AbortSignal|null} [signal=null] - The optional AbortSignal for canceling the request.
 * @returns {Promise<any>} A Promise that resolves with the submission values.
 *
 * @example
 * const submissionValues = await getSubmissionValues("submissionUUID", { type: "type1" }, controller.signal);
 */
export const getSubmissionValues = (uuid, params = {}, signal = null) => apiHandler({
	method: "get",
	url: `submissions/${uuid}/values`,
	dataOrParams: params,
	signal
});

/**
 * Deletes a submission
 *
 * @param {string} uuid - The UUID of the submission to delete.
 * @param {object} [params={}] - Optional parameters for the request.
 * @param {AbortSignal|null} [signal=null] - The optional AbortSignal for canceling the request.
 * @returns {Promise<any>} A Promise that resolves with the submission information.
 *
 * @example
 * const submissionInfo = await getSubmission("submissionUUID", { includeDetails: true }, controller.signal);
 */
export const deleteSubmission = (uuid, params = {}, signal = null) => apiHandler({
	method: "delete",
	url: `submissions/${uuid}`,
	dataOrParams: params,
	signal
});

/**
 * Creates a new submission for a specific form.
 *
 * @param {string} form_key - The key of the form to create a new submission for.
 * @param {object} [data={}] - The data for the new submission.
 * @param {AbortSignal|null} [signal=null] - The optional AbortSignal for canceling the request.
 * @returns {Promise<any>} A Promise that resolves with the newly created submission.
 *
 * @example
 * const newSubmission = await newFormSubmission("formKey", { key: "value" }, controller.signal);
 */
export const checkNewFormSubmission = (form_key, data = {}, signal = null) => apiHandler({
	method: "post",
	url: `forms/${form_key}/check`,
	dataOrParams: data,
	signal
});

export const newFormSubmission = (form_key, data = {}, signal = null) => apiHandler({
	method: "post",
	url: `forms/${form_key}/new`,
	dataOrParams: data,
	signal
});

/**
 * Submits a form with a specific submission UUID.
 *
 * @param {string} form_key - The key of the form to submit.
 * @param {string} submission_uuid - The UUID of the submission to submit.
 * @param {object} [data={}] - The data to submit with the form.
 * @param {AbortSignal|null} [signal=null] - The optional AbortSignal for canceling the request.
 * @returns {Promise<any>} A Promise that resolves when the form is submitted.
 *
 * @example
 * const submittedForm = await submitForm("formKey", "submissionUUID", { key: "value" }, controller.signal);
 */
export const submitForm = (form_key, submission_uuid, data = {}, signal = null) => apiHandler({
	method: "post",
	url: `forms/${form_key}/submit/${submission_uuid}`,
	dataOrParams: data,
	signal
});

/**
 * Calculates the panel cost for a specific submission.
 *
 * @param {string} uuid - The UUID of the submission to calculate panel cost for.
 * @param {object} [data={}] - The data for calculating panel cost.
 * @param {AbortSignal|null} [signal=null] - The optional AbortSignal for canceling the request.
 * @returns {Promise<any>} A Promise that resolves with the calculated panel cost.
 *
 * @example
 * const panelCost = await calculatePanelCost("submissionUUID", { key: "value" }, controller.signal);
 */
export const calculatePanelCost = (uuid, data = {}, signal = null) => apiHandler({
	method: "post",
	url: `/submissions/${uuid}/calc-panel`,
	dataOrParams: data,
	signal
});

/**
 * Exports a submission with a specific UUID.
 *
 * @param {string} uuid - The UUID of the submission to export.
 * @param {object} [params={}] - Optional parameters for the export request.
 * @param {AbortSignal|null} [signal=null] - The optional AbortSignal for canceling the request.
 * @returns {Promise<any>} A Promise that resolves with the exported data.
 *
 * @example
 * const exportedSubmission = await exportSubmission("submissionUUID", { format: "csv" }, controller.signal);
 */
export const exportSubmission = (uuid, params = {}, signal = null) => apiHandler({
	method: "get",
	url: `/submissions/${uuid}/export`,
	dataOrParams: params,
	signal
});

/**
 * Marks a submission as uncompleted.
 *
 * @param {string} uuid - The UUID of the submission to mark as uncompleted.
 * @param {object} [params={}] - Optional parameters for marking as uncompleted.
 * @param {AbortSignal|null} [signal=null] - The optional AbortSignal for canceling the request.
 * @returns {Promise<any>} A Promise that resolves when the submission is marked as uncompleted.
 *
 * @example
 * const uncompletedSubmission = await uncompleteSubmission("submissionUUID", { reason: "Incomplete data" }, controller.signal);
 */
export const uncompleteSubmission = (uuid, params = {}, signal = null) => apiHandler({
	method: "get",
	url: `/submissions/${uuid}/uncomplete`,
	dataOrParams: params,
	signal
});

/**
 * Syncs a submission with a specific UUID.
 *
 * @param {string} uuid - The UUID of the submission to sync.
 * @param {object} [data={}] - The data to sync with the submission.
 * @param {AbortSignal|null} [signal=null] - The optional AbortSignal for canceling the request.
 * @returns {Promise<any>} A Promise that resolves when the submission is synced.
 *
 * @example
 * const syncedSubmission = await syncSubmission("submissionUUID", { data }, controller.signal);
 */
export const syncSubmission = (uuid, data = {}, signal = null) => apiHandler({
	method: "post",
	url: `/export/ibs/sync/${uuid}`,
	dataOrParams: data,
	signal
});

/**
 * Retrieves quotes for a submission with a specific UUID.
 *
 * @param {string} uuid - The UUID of the submission to retrieve quotes for.
 * @param {object} [data={}] - Optional parameters for retrieving quotes.
 * @param {AbortSignal|null} [signal=null] - The optional AbortSignal for canceling the request.
 * @returns {Promise<any>} A Promise that resolves with the quotes.
 *
 * @example
 * const submissionQuotes = await getQuotes("submissionUUID", { data }, controller.signal);
 */
export const getQuotes = (uuid, data = {}, signal = null) => apiHandler({
	method: "get",
	url: `/submissions/${uuid}/quotes`,
	dataOrParams: data,
	signal
});

/**
 * Retrieves exports for a submission with a specific UUID.
 *
 * @param {string} uuid - The UUID of the submission to retrieve exports for.
 * @param {object} [data={}] - Optional parameters for retrieving exports.
 * @param {AbortSignal|null} [signal=null] - The optional AbortSignal for canceling the request.
 * @returns {Promise<any>} A Promise that resolves with the exports.
 *
 * @example
 * const submissionExports = await getExports("submissionUUID", { data }, controller.signal);
 */
export const getExports = (uuid, data = {}, signal = null) => apiHandler({
	method: "get",
	url: `/submissions/${uuid}/exports`,
	dataOrParams: data,
	signal
});

/**
 * Retrieves children submissions for a submission with a specific UUID.
 *
 * @param {string} uuid - The UUID of the submission to retrieve children for.
 * @param {object} [data={}] - Optional parameters for retrieving children.
 * @param {AbortSignal|null} [signal=null] - The optional AbortSignal for canceling the request.
 * @returns {Promise<any>} A Promise that resolves with the children submissions.
 *
 * @example
 * const submissionChildren = await getChildren("submissionUUID", { data }, controller.signal);
 */
export const getChildren = (uuid, data = {}, signal = null) => apiHandler({
	method: "get",
	url: `/submissions/${uuid}/children`,
	dataOrParams: data,
	signal
});

export const getComparison = (source_uuid, target_uuid, params = {}, signal = null) => apiHandler({
	method: "get",
	url: `/submissions/${source_uuid}/comparison/${target_uuid}`,
	dataOrParams: params,
	signal
});

export const getAuditViaSubmission = (uuid, params = {}, signal = null) => apiHandler({
	method: "get",
	url: `/submissions/${uuid}/audit`,
	dataOrParams: params,
	signal
});

export const unlinkAssessment = (uuid, params = {}, signal = null) => apiHandler({
	method: "get",
	url: `/submissions/${uuid}/unlink-assessment`,
	dataOrParams: params,
	signal
});

export const getTotals = (uuid, params = {}, signal = null) => apiHandler({
	method: "get",
	url: `/submissions/${uuid}/totals`,
	dataOrParams: params,
	signal
});

export const getRepairBooking = (uuid, params = {}, signal = null) => apiHandler({
	method: "get",
	url: `submissions/${uuid}/repair-booking`,
	dataOrParams: params,
	signal
});
