import { Close } from '@mui/icons-material'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Stack } from '@mui/material'
import { Field, Formik } from 'formik'
import { TextField } from 'formik-mui'
import { useMemo } from 'react'
import { mergeValuesWithInital } from '~/helpers/dataHelpers'

const BookingSlotAssignDialog = ({ capacityData = [], slotCategories = [], onClose, onSave }) => {

	// merge active data with defaults
	const data = useMemo(() => mergeValuesWithInital(capacityData, slotCategories.map(s => ({ job_category_id: s.id, key: s.key, slots: s.booking_default_capacity, name: s.name }))), []);

	return (
		<Formik initialValues={{ capacity: data }} onSubmit={onSave}>
			{({ handleSubmit }) => (
				<Dialog open={true} fullWidth maxWidth="xs" onClose={onClose}>
					<DialogTitle display="flex" alignItems="center" justifyContent="space-between">
						<div>Assign Slots</div>
						<IconButton aria-label="close" onClick={onClose} edge="end"><Close /></IconButton>
					</DialogTitle>
					<DialogContent>
						<Stack spacing={1} mb={3} position="relative">
							{slotCategories.map((category, index) => (
								<Box key={category.key} display="flex" alignItems="center" justifyContent="space-between">
									<div>{category.name}</div>
									<Field
										name={`capacity.${index}.slots`}
										component={TextField}
										variant="standard"
										type="number"
										style={{ width: '40%' }}
										InputProps={{ inputProps: { min: 0 } }}
									/>
								</Box>
							))}
						</Stack>
					</DialogContent>
					<DialogActions>
						<Button color="secondary" variant="outlined" fullWidth onClick={onClose}>
							Cancel
						</Button>
						<Button color="primary" variant="contained" fullWidth onClick={handleSubmit}>
							Assign Slots
						</Button>
					</DialogActions>
				</Dialog>
			)}
		</Formik>
	)
}

export default BookingSlotAssignDialog